export const MENUS_LIST_REQUEST = 'MENUS_LIST_REQUEST';
export const MENUS_LIST_SUCCESS = 'MENUS_LIST_SUCCESS';
export const MENUS_LIST_FAIL = 'MENUS_LIST_FAIL';

export const MENUS_SAVE_REQUEST = 'MENUS_SAVE_REQUEST';
export const MENUS_SAVE_SUCCESS = 'MENUS_SAVE_SUCCESS';
export const MENUS_SAVE_FAIL = 'MENUS_SAVE_FAIL';

export const MENUS_DELETE_REQUEST = 'MENUS_DELETE_REQUEST';
export const MENUS_DELETE_SUCCESS = 'MENUS_DELETE_SUCCESS';
export const MENUS_DELETE_FAIL = 'MENUS_DELETE_FAIL';

export const SELECTED_MENU = 'SELECTED_MENU';

export const MENUS_SIDEBAR_IS_TOGGLED = 'MENUS_SIDEBAR_IS_TOGGLED';