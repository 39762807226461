import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function PermissionsCount() {

    const permissionsList = useSelector(state => state.permissionsList);
    const { permissions,
        loading,
        error } = permissionsList;

    useEffect(() => {

    }, [permissions]);

    return (
        <React.Fragment>
            {loading ? <div>Loading...</div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{permissions.length} Permissions</p>
            }
        </React.Fragment>
    );
}