const Errors = {
    required: (field) => {
        return `${field.name} est obligatoire.`;
    },
    min: (field)=>{
        return `${field.name} doit comporter au moins ${field.number} de caractères`;
    },
    confirmPassword: ()=>{
        return `Les deux mots de passe doivent être identiques`;
    },
    domain: ()=>{
        return `le nom de domaine invalid`;
    }
}

export default Errors ;