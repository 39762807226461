export const POSTTYPE_LIST_REQUEST = 'POSTTYPE_LIST_REQUEST';
export const POSTTYPE_LIST_SUCCESS = 'POSTTYPE_LIST_SUCCESS';
export const POSTTYPE_LIST_FAIL = 'POSTTYPE_LIST_FAIL';

export const POSTTYPE_SAVE_REQUEST = 'POSTTYPE_SAVE_REQUEST';
export const POSTTYPE_SAVE_SUCCESS = 'POSTTYPE_SAVE_SUCCESS';
export const POSTTYPE_SAVE_FAIL = 'POSTTYPE_SAVE_FAIL';

export const POSTTYPE_DELETE_REQUEST = 'POSTTYPE_DELETE_REQUEST';
export const POSTTYPE_DELETE_SUCCESS = 'POSTTYPE_DELETE_SUCCESS';
export const POSTTYPE_DELETE_FAIL = 'POSTTYPE_DELETE_FAIL';

export const SELECTED_POSTTYPE = 'SELECTED_POSTTYPE';

export const POSTTYPE_SIDEBAR_IS_TOGGLED = 'POSTTYPE_SIDEBAR_IS_TOGGLED';

export const POSTTYPE_RESTORE_REQUEST = 'POSTTYPE_RESTORE_REQUEST';
export const POSTTYPE_RESTORE_SUCCESS = 'POSTTYPE_RESTORE_SUCCESS';
export const POSTTYPE_RESTORE_FAIL    = 'POSTTYPE_RESTORE_FAIL';

export const POSTTYPE_DESTROY_REQUEST = 'POSTTYPE_DESTROY_REQUEST';
export const POSTTYPE_DESTROY_SUCCESS = 'POSTTYPE_DESTROY_SUCCESS';
export const POSTTYPE_DESTROY_FAIL = 'POSTTYPE_DESTROY_FAIL';

export const POSTTYPE_TRASHED_LIST_REQUEST = 'POSTTYPE_TRASHED_LIST_REQUEST';
export const POSTTYPE_TRASHED_LIST_SUCCESS = 'POSTTYPE_TRASHED_LIST_SUCCESS';
export const POSTTYPE_TRASHED_LIST_FAIL = 'POSTTYPE_TRASHED_LIST_FAIL';