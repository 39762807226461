export const PERMISSIONS_LIST_REQUEST = 'PERMISSIONS_LIST_REQUEST';
export const PERMISSIONS_LIST_SUCCESS = 'PERMISSIONS_LIST_SUCCESS';
export const PERMISSIONS_LIST_FAIL = 'PERMISSIONS_LIST_FAIL';
export const PERMISSIONS_SAVE_REQUEST = 'PERMISSIONS_SAVE_REQUEST';
export const PERMISSIONS_SAVE_SUCCESS = 'PERMISSIONS_SAVE_SUCCESS';
export const PERMISSIONS_SAVE_FAIL = 'PERMISSIONS_SAVE_FAIL';
export const PERMISSIONS_DELETE_REQUEST = 'PERMISSIONS_DELETE_REQUEST';
export const PERMISSIONS_DELETE_SUCCESS = 'PERMISSIONS_DELETE_SUCCESS';
export const PERMISSIONS_DELETE_FAIL = 'PERMISSIONS_DELETE_FAIL';
export const SELECTED_PERMISSION = 'SELECTED_PERMISSION';
export const PERMISSIONS_SIDEBAR_IS_TOGGLED = 'PERMISSIONS_SIDEBAR_IS_TOGGLED';