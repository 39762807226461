import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listUsers, deleteUser, selectedUser, toggleUsersSidebar} from '../../actions/usersActions';
import Swal from 'sweetalert2';

export default function UsersDatatable() {

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const usersList = useSelector(state => state.usersList);
    const { users, 
            loading,
            error } = usersList;

    const userDelete = useSelector(state => state.usersDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = userDelete;

    const usersSave = useSelector(state => state.usersSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = usersSave;


    const columns = [
        {
            name: 'Image',
            cell: ( user ) => <span className="avatar-contact avatar-online"><img src={ user.users_image ? user.users_image : "/app-assets/images/user/default-avatar.png" } alt="avatar" /></span>,
        },
        {
            name: 'Name',
            selector: 'users_name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'users_email',
            sortable: true,
        },
        {
            cell: ( user ) => <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { editUserHandler(user); } }>
                <i className="material-icons">edit</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( user ) => <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deleteUserHandler(user); } }>
                <i className="material-icons">delete</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(users);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listUsers());
        }

    }, [successSave, deleteSuccess, users]);

    const editUserHandler = ( userData ) => {
        dispatch(selectedUser(userData));
        dispatch(toggleUsersSidebar(true));
    }

    const deleteUserHandler = ( userData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteUser(userData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Utilisateur bien supprimer.',
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = users.filter(item => item.users_name && item.users_name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div>loading ...</div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

