const { POSTS_LIST_REQUEST, 
        POSTS_LIST_SUCCESS, 
        POSTS_LIST_FAIL,
        POSTS_SAVE_REQUEST,
        POSTS_SAVE_SUCCESS,
        POSTS_SAVE_FAIL, 
        POSTS_DELETE_REQUEST,
        POSTS_DELETE_SUCCESS,
        POSTS_DELETE_FAIL,
        SELECTED_POSTS,
        POSTS_LANG_REQUEST,
        POSTS_LANG_SUCCESS,
        POSTS_LANG_FAIL,
        POST_RESTORE_REQUEST,
        POST_RESTORE_SUCCESS,
        POST_RESTORE_FAIL,
        POST_DESTROY_REQUEST,
        POST_DESTROY_SUCCESS,
        POST_DESTROY_FAIL,
        SELECTED_POST_CATEGORIES_ITEMS,
        POSTS_MENU_LIST_REQUEST,
        POSTS_MENU_LIST_SUCCESS,
        POSTS_MENU_LIST_FAIL
    } = require("../constants/postsConstants");

function postsListReducer(state = {loading:false, posts:[]}, action){

    switch(action.type){
        case POSTS_LIST_REQUEST:
            return {loading : true};
        case POSTS_LIST_SUCCESS:
            return {loading: false, posts: action.payload};
        case POSTS_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function listMenuPostsReducer(state = {loading:false, posts:[]}, action){

    switch(action.type){
        case POSTS_MENU_LIST_REQUEST:
            return {loading : true};
        case POSTS_MENU_LIST_SUCCESS:
            return {loading: false, posts: action.payload};
        case POSTS_MENU_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function postsSaveReducer(state = {posts:[]}, action){

    switch(action.type){
        case POSTS_SAVE_REQUEST:
            return {loading : true};
        case POSTS_SAVE_SUCCESS:
            return {loading: false, success: true, posts: action.payload};
        case POSTS_SAVE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function postRestoreReducer(state = { post: [] }, action) {

    switch (action.type) {
        case POST_RESTORE_REQUEST:
            return { loading: true };
        case POST_RESTORE_SUCCESS:
            return { loading: false, success: true, post: action.payload };
        case POST_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function destroyPostReducer(state = { post: [] }, action) {

    switch (action.type) {
        case POST_DESTROY_REQUEST:
            return { loading: true };
        case POST_DESTROY_SUCCESS:
            return { loading: false, success: true, post: action.payload };
        case POST_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postsDeleteReducer(state = {posts:[]}, action){

    switch(action.type){
        case POSTS_DELETE_REQUEST:
            return {loading : true};
        case POSTS_DELETE_SUCCESS:
            return {loading: false, success: true, posts: action.payload};
        case POSTS_DELETE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function selectedPostReducer(state = { posts: null }, action) {

    switch (action.type) {
        case SELECTED_POSTS:
            return { posts: action.payload };
        default:
            return state;
    }

}

function setSelectedPostCategorieItemsReducer(state = { selectedPostCategoriesItems: [] }, action) {

    switch (action.type) {
        case SELECTED_POST_CATEGORIES_ITEMS:
            return { selectedPostCategoriesItems: action.payload };
        default:
            return state;
    }

}

function postsLangReducer(state = {loading:false, post: []}, action){

    switch(action.type){
        case POSTS_LANG_REQUEST:
            return {loading : true};
        case POSTS_LANG_SUCCESS:
            return {loading: false, post: action.payload};
        case POSTS_LANG_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

export { postsListReducer, postsSaveReducer, postsDeleteReducer, selectedPostReducer, postsLangReducer, destroyPostReducer, postRestoreReducer, setSelectedPostCategorieItemsReducer, listMenuPostsReducer}
