import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function LanguagesCount(  ) {

    const { authorizdApps,  loading,  error } = useSelector(state => state.authorizedAppsList);

    useEffect(() => {
        
    }, [authorizdApps]);     

    return (
        <React.Fragment>
            {loading ? <div>
                Loading...
            </div> :  
                error ? <div>{error}</div> : 
                
                    <p className="m-0 text-muted">{authorizdApps.length} Applications</p>
            }
        </React.Fragment>
    );
}