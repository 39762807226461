import React, { Component } from 'react'
import { logOut } from '../../../actions/authActions'
import { useDispatch } from 'react-redux';
import {Link } from 'react-router-dom'


export default function ProfileDropdown() {
    const dispatch = useDispatch();
    return (
        <ul className="dropdown-content" id="profile-dropdown">
            <li><Link to='/profile' className="grey-text text-darken-1" href=""><i className="material-icons">person_outline</i> Profile</Link></li>
            {/* <li><a className="grey-text text-darken-1" href=""><i className="material-icons">chat_bubble_outline</i> Chat</a></li> */}
            {/* <li><a className="grey-text text-darken-1" href=""><i className="material-icons">help_outline</i> Help</a></li> */}
            {/* <li className="divider"></li> */}
            {/* <li><a className="grey-text text-darken-1" href=""><i className="material-icons">lock_outline</i> Lock</a></li> */}
            <li><a className="grey-text text-darken-1" href="" onClick={()=>{dispatch(logOut(()=>{ window.location.href = "/login"}))}}><i className="material-icons">keyboard_tab</i> Logout</a></li>
        </ul>
    )
}
