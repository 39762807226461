import React, { useEffect} from 'react';
import { pathListner } from '../actions/pathActions';
import {  useDispatch } from 'react-redux'



export default function Dashboard() {
    const dispatch = useDispatch();
   
    useEffect(() => {

        dispatch(pathListner('/'));

        return () => {
            //
        }
    }, [])
    return (
        <React.Fragment>
            <h1>DashBoard</h1>
        </React.Fragment>
    );
}
