import React from 'react'
import PropTypes from 'prop-types'

const FileManagerProgressebar = ({ percentage }) => {
    return (
        <div className="progress">
            <div className="determinate" style={{width: percentage+'%'}}></div>
        </div>
    )
}

FileManagerProgressebar.propTypes = {
    percentage: PropTypes.number.isRequired,
}

export default FileManagerProgressebar
