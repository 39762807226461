import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'
const { 
    CATEGORIES_POST_TYPES_LIST_REQUEST,
    CATEGORIES_POST_TYPES_LIST_SUCCESS,
    CATEGORIES_POST_TYPES_LIST_FAIL,
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,
    CATEGORIES_SAVE_REQUEST,
    CATEGORIES_SAVE_SUCCESS,
    CATEGORIES_SAVE_FAIL,
    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_DELETE_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORIES_SIDEBAR_IS_TOGGLED,
    CATEGORY_LANG_REQUEST,
    CATEGORY_LANG_SUCCESS,
    CATEGORY_LANG_FAIL,
    CATEGORY_RESTORE_REQUEST,
    CATEGORY_RESTORE_SUCCESS,
    CATEGORY_RESTORE_FAIL,
    CATEGORY_DESTROY_REQUEST,
    CATEGORY_DESTROY_SUCCESS,
    CATEGORY_DESTROY_FAIL
 } = require("../constants/categoriesConstants");

const listCategories = (cb) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORIES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/categories',
            headers
        });
        typeof cb == "function" && cb(data);

        dispatch({ type: CATEGORIES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIES_LIST_FAIL, payload: error.message });
    }

}


const restoreCategory = (category, callback) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_RESTORE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy+"/api/categories/restore/" + category.id_cat,
            headers
        });
        callback(data);
        dispatch({ type: CATEGORY_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_RESTORE_FAIL, payload: error.message });
    }

}

const destroyCategory = (category, callback) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_DESTROY_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/categories/destroy/" + category.id_cat,
            headers
        });
        callback(data);
        dispatch({ type: CATEGORY_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_DESTROY_FAIL, payload: error.message });
    }

}

const trashedCategories = (cb) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORIES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/categories/trashed',
            headers
        });
        typeof cb == "function" && cb(data);

        dispatch({ type: CATEGORIES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIES_LIST_FAIL, payload: error.message });
    }

}

const listPostTypesCategories = (id_post_type) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORIES_POST_TYPES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/categories/post-type/' + id_post_type,
            headers
        });

        dispatch({ type: CATEGORIES_POST_TYPES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIES_POST_TYPES_LIST_FAIL, payload: error.message });
    }

}

const saveCategory = (category, callback) => async (dispatch) => {


    if (!category.id) {
        dispatch({ type: CATEGORIES_SAVE_REQUEST, payload: { category } });
        axios({
            method: 'post',
            url: apiProxy+"/api/categories",
            data: category,
            headers
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: CATEGORIES_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: CATEGORIES_SAVE_FAIL, payload: err.response.data.message });
        });

    } else {
        dispatch({ type: CATEGORIES_SAVE_REQUEST, payload: { category } });
        axios({
            method: 'patch',
            url: apiProxy+"/api/categories/" + category.id,
            data: category,
            headers
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: CATEGORIES_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: CATEGORIES_SAVE_FAIL, payload: err.response.data.message });
        });;

    }



}

const deleteCategory = (category, callback) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORIES_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/categories/" + category.id_cat,
            headers
        });
        callback(data);
        dispatch({ type: CATEGORIES_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIES_DELETE_FAIL, payload: error.message });
    }

}



const findCategoryByLang = (id_cat,id_lang,cb) => async (dispatch) => {


    try {
        dispatch({ type: CATEGORY_LANG_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/categories/' + id_cat + '/' + id_lang,
            headers
        });
        typeof cb == "function" && cb(data);
        dispatch({ type: CATEGORY_LANG_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_LANG_FAIL, payload: error.message });
    }
  
}


const selectedCategory = (category) => async (dispatch) => {

    try {
        dispatch({ type: CATEGORY_DETAILS_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/categories/' + category.id_cat,
            headers
        });
        // typeof cb == "function" && cb(data);

        dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_DETAILS_FAIL, payload: error.message });
    }
}

const toggleCategoriesSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: CATEGORIES_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listCategories, saveCategory, deleteCategory, selectedCategory, toggleCategoriesSidebar, findCategoryByLang, listPostTypesCategories, trashedCategories, restoreCategory, destroyCategory}