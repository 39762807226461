import React, { Component } from 'react'

export default class NotificationsDropdown extends Component {
    render() {
        return (
            <ul className="dropdown-content" id="notifications-dropdown">
                <li>
                    <h6>NOTIFICATIONS<span className="new badge">5</span></h6>
                </li>
                <li className="divider"></li>
                <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle cyan small">add_shopping_cart</span> A new order has been placed!</a>
                    <time className="media-meta grey-text darken-2" date-time="2015-06-12T20:50:48+08:00">2 hours ago</time>
                </li>
                <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle red small">stars</span> Completed the task</a>
                    <time className="media-meta grey-text darken-2" date-time="2015-06-12T20:50:48+08:00">3 days ago</time>
                </li>
                <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle teal small">settings</span> Settings updated</a>
                    <time className="media-meta grey-text darken-2" date-time="2015-06-12T20:50:48+08:00">4 days ago</time>
                </li>
                <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle deep-orange small">today</span> Director meeting started</a>
                    <time className="media-meta grey-text darken-2" date-time="2015-06-12T20:50:48+08:00">6 days ago</time>
                </li>
                <li><a className="black-text" href="#!"><span className="material-icons icon-bg-circle amber small">trending_up</span> Generate monthly report</a>
                    <time className="media-meta grey-text darken-2" date-time="2015-06-12T20:50:48+08:00">1 week ago</time>
                </li>
            </ul>
        )
    }
}
