export const AUTHORIZED_APPS_LIST_REQUEST = 'AUTHORIZED_APPS_LIST_REQUEST';
export const AUTHORIZED_APPS_LIST_SUCCESS = 'AUTHORIZED_APPS_LIST_SUCCESS';
export const AUTHORIZED_APPS_LIST_FAIL = 'AUTHORIZED_APPS_LIST_FAIL';

export const AUTHORIZED_APPS_SAVE_REQUEST = 'AUTHORIZED_APPS_SAVE_REQUEST';
export const AUTHORIZED_APPS_SAVE_SUCCESS = 'AUTHORIZED_APPS_SAVE_SUCCESS';
export const AUTHORIZED_APPS_SAVE_FAIL = 'AUTHORIZED_APPS_SAVE_FAIL';

export const AUTHORIZED_APPS_DELETE_REQUEST = 'AUTHORIZED_APPS_DELETE_REQUEST';
export const AUTHORIZED_APPS_DELETE_SUCCESS = 'AUTHORIZED_APPS_DELETE_SUCCESS';
export const AUTHORIZED_APPS_DELETE_FAIL = 'AUTHORIZED_APPS_DELETE_FAIL';

export const SELECTED_AUTHORIZED_APP = 'SELECTED_AUTHORIZED_APP';

export const AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED = 'AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED';

export const CURRENT_AUTHORIZED_APP_REQUEST = 'CURRENT_AUTHORIZED_APP_REQUEST';
export const CURRENT_AUTHORIZED_APP_SUCCESS = 'CURRENT_AUTHORIZED_APP_SUCCESS';
export const CURRENT_AUTHORIZED_APP_FAIL = 'CURRENT_AUTHORIZED_APP_FAIL';

export const AUTHORIZED_APPS_RESTORE_REQUEST = '  AUTHORIZED_APPS_RESTORE_REQUEST';
export const AUTHORIZED_APPS_RESTORE_SUCCESS = '    AUTHORIZED_APPS_RESTORE_SUCCESS';
export const AUTHORIZED_APPS_RESTORE_FAIL = '    AUTHORIZED_APPS_RESTORE_FAIL';

export const AUTHORIZED_APPS_DESTROY_REQUEST = 'AUTHORIZED_APPS_DESTROY_REQUEST';
export const AUTHORIZED_APPS_DESTROY_SUCCESS = 'AUTHORIZED_APPS_DESTROY_SUCCESS';
export const AUTHORIZED_APPS_DESTROY_FAIL = 'AUTHORIZED_APPS_DESTROY_FAIL';