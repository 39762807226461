export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_FAIL = 'USERS_LIST_FAIL';

export const USERS_SAVE_REQUEST = 'USERS_SAVE_REQUEST';
export const USERS_SAVE_SUCCESS = 'USERS_SAVE_SUCCESS';
export const USERS_SAVE_FAIL = 'USERS_SAVE_FAIL';

export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAIL = 'USERS_DELETE_FAIL';

export const SELECTED_USER = 'SELECTED_USER';

export const USERS_SIDEBAR_IS_TOGGLED = 'USERS_SIDEBAR_IS_TOGGLED';

export const USER_AUTH_PERMISSIONS_REQUEST = 'USER_AUTH_PERMISSIONS_REQUEST,';
export const USER_AUTH_PERMISSIONS_SUCCESS = 'USER_AUTH_PERMISSIONS_SUCCESS,';
export const USER_AUTH_PERMISSIONS_FAIL    = 'USER_AUTH_PERMISSIONS_FAIL';