import React,{useEffect} from 'react'
import LoginForm from './forms/loginForm'
import { ToastContainer } from 'react-toastify'
import { isLoggedIn } from '../../actions/authActions'
import { useDispatch, useSelector} from 'react-redux'
import NotFound from '../../components/errors/notFound'

export default function Login() {
    const dispatch = useDispatch();
    const { loading, user} = useSelector(state => state.login);

    // const { authToken, loading } = authTokenSelector;
    useEffect(() => {
        document.body.classList.add('login-bg');
        document.querySelector('#main').classList.add('p-0');
        if (!user){
            dispatch(isLoggedIn());
        }
        
    }, [user])
    return (
    <React.Fragment>

            {loading ? <div>loading ...</div> : !user?.isLoggedIn ? (
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className="row">
                        <div className="col s12">
                            <div className="container">
                                <div id="login-page" className="row">
                                    <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                                        <LoginForm />
                                    </div>
                                </div>
                            </div>
                            <div className="content-overlay"></div>
                        </div>
                    </div>
                    </>
            ):
                <NotFound/>
            }

        
       
    </React.Fragment>
    )
}
