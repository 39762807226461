import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { menuItems } from '../Layout/Navbar/MenuData'
import { listPermissions, savePermission, selectedPermissions, togglePermissionsSidebar } from '../../actions/PermissionsActions';
import { customStyles } from '../../styles/reactSelect'
import Select from 'react-select'

const CreatePermissionSchema = Yup.object().shape({
    name: Yup.string().min(3, Errors.min({ name: "le nom", number: "3" })).required(Errors.required({ name: "le nom" })),
    key: Yup.string().required(Errors.required({ name: "Element " }))
})

export default function FormComponent() {

    const dispatch = useDispatch();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [create, setCreate] = useState(false);
    const [update, setUpdate] = useState(false);
    const [del, setDel] = useState(false);
    const [show, setShow] = useState(false);

    const { sideBarItems } = useSelector(state => state.sideBarItems);

    const [saveSuccess, setSaveSuccess] = useState(false);

    const permissionsToggleSidebar = useSelector(state => state.togglepermissionsSidebar);

    const permissionSelected = useSelector(state => state.selectedPermission);

    const { loading: loadingSave, success: successSave, error: errorSave } = useSelector(state => state.permissionsSave);
    

    let mergeMenuItems = [];
    sideBarItems.map(item => {
        if (item.content) {
            mergeMenuItems.push(...item.content)
        }else{
            mergeMenuItems.push(item)
        }
    })

    const menuItemsOptions = mergeMenuItems.map(cat => {
        return {
            label: cat.label,
            value: cat.key
        }
    })
    menuItemsOptions.unshift({ label: "Tous les onglets", value:"ALL"})

    useEffect(() => {

        if (permissionSelected.permission) {
            setId(permissionSelected.permission.id);
            setName(permissionSelected.permission.permissions_name);
            setKey(permissionSelected.permission.key);
            setCreate(permissionSelected.permission.create);
            setUpdate(permissionSelected.permission.update);
            setDel(permissionSelected.permission.delete);
            setShow(permissionSelected.permission.show);

        } else {
            setId('');
            setName('');
            setKey('');
            setCreate(false);
            setUpdate(false);
            setDel(false);
            setShow(false);
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listPermissions());
            toast.success('Pirmission Enregistrer');
        }

    }, [permissionSelected, saveSuccess]);


    const closeFormSidebar = () => {
        dispatch(selectedPermissions(null));
        dispatch(togglePermissionsSidebar(false));
    }

    const handleSubmit = (values) => {
        dispatch(savePermission(values, (response) => {
            setSaveSuccess(true);
        }));
    }

    return <React.Fragment>
        {loadingSave && <div>loading ...</div>}
        {errorSave && <div>{errorSave}</div>}

        <div className={permissionsToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title contact-title-label">{id === '' ? "Create New Permission" : "Update Permission"}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            name: name ? name : '',
                            key: key ? key : "",
                            create :  create ?  create : false,
                            update : update ?update : false,
                            del : del ?del : false,
                            show : show ?show : false
                        }}
                        validationSchema={CreatePermissionSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            closeFormSidebar();
                            handleSubmit(values);
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form className="edit-contact-item mb-5 mt-5">
                                <div className="row">

                                    <div className="input-field col m12 s12">
                                        <Select
                                            name="key"
                                            id="key"
                                            value={menuItemsOptions.filter(opt => { return (opt.value === key) })}
                                            onChange={(opt, e) => {
                                                setKey(opt?.value)
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder=""
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={menuItemsOptions}
                                        />
                                        <label htmlFor="categorie" className="active">Element sélectionnée</label>
                                        {errors.key && touched.key ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.key}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="name" name="name" type="text" className={errors.name ? "error validate" : "validate"} />
                                        <label htmlFor="name" className={name !== '' ? 'active' : ''}>Name</label>
                                        {errors.name && touched.name ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.name}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s6">
                                        <p className="mb-1">
                                            <label>
                                                <Field type="checkbox" name="create"/>
                                                <span>Créer</span>
                                            </label>
                                        </p>
                                    </div>

                                    <div className="input-field col s6">
                                        <p className="mb-1">
                                            <label>
                                                <Field type="checkbox" name="update"/>
                                                <span>Modifier</span>
                                            </label>
                                        </p>
                                    </div>

                                    <div className="input-field col s6">
                                        <p className="mb-1">
                                            <label>
                                                <Field type="checkbox" name="del"/>
                                                <span>Supprimer</span>
                                            </label>
                                        </p>
                                    </div>

                                    <div className="input-field col s6">
                                        <p className="mb-1">
                                            <label>
                                                <Field type="checkbox" name="show"/>
                                                <span>Affichier</span>
                                            </label>
                                        </p>
                                    </div>

                                </div>
                                
                                <div className="card-action pl-0 pr-0 right-align">
                                    <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                        {!id? (
                                            <span>Ajouter</span>
                                            ) : (
                                            <span>Modifier</span>
                                        )}
                                    </button>
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>
}