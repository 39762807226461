import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathListner } from '../../actions/pathActions';
import 'react-toastify/dist/ReactToastify.css';

import { listPostTemplates, listTrashedTemplates } from '../../actions/postTemplatesActions';
import PostTemplatesFiltre from './PostTemplatesFiltre';
import PostTemplatesDatatable from './PostTemplatesDatatable';


export default function PostTemplates(props) {

    const dispatch = useDispatch();

    useEffect(() => {

        if (typeof props.match.params.trush != "undefined") {
            dispatch(listTrashedTemplates());
        } else {
            dispatch(listPostTemplates());
        }
        dispatch(pathListner('/post-templates'));

    }, [])

    

    return <React.Fragment>

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">
                    
                    <div className="contact-overlay"></div>

                    <div style={{bottom: '54px' , right: '19px'}} className="fixed-action-btn direction-top">
                        <Link to="/post-templates-form" className="btn-floating btn-large gradient-shadow">
                            <i className="material-icons">add_box</i>
                        </Link>
                    </div>

                    <PostTemplatesFiltre props={props} />

                    <PostTemplatesDatatable />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    </React.Fragment>;
}
