
import { menuItems } from '../components/Layout/Navbar/MenuData'

export function setSideBarItemsReducer(state = { sideBarItems: menuItems }, action) {

    switch (action.type) {
        case "SIDEBAR_ITEMS_SET_SUCCESS":
            return { sideBarItems: action.payload };
        default:
            return state;
    }

}