export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST';
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS';
export const CATEGORIES_LIST_FAIL = 'CATEGORIES_LIST_FAIL';

export const CATEGORIES_SAVE_REQUEST = 'CATEGORIES_SAVE_REQUEST';
export const CATEGORIES_SAVE_SUCCESS = 'CATEGORIES_SAVE_SUCCESS';
export const CATEGORIES_SAVE_FAIL = 'CATEGORIES_SAVE_FAIL';

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST';
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';
export const CATEGORIES_DELETE_FAIL = 'CATEGORIES_DELETE_FAIL';

export const CATEGORIES_SIDEBAR_IS_TOGGLED = 'CATEGORIES_SIDEBAR_IS_TOGGLED';

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST';
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS';
export const CATEGORY_DETAILS_FAIL    = 'CATEGORY_DETAILS_FAIL';


export const CATEGORY_LANG_REQUEST    = 'CATEGORY_LANG_REQUEST';
export const CATEGORY_LANG_SUCCESS    = 'CATEGORY_LANG_SUCCESS';
export const CATEGORY_LANG_FAIL       = 'CATEGORY_LANG_FAIL';

export const CATEGORIES_POST_TYPES_LIST_REQUEST       = 'CATEGORIES_POST_TYPES_LIST_REQUEST';
export const CATEGORIES_POST_TYPES_LIST_SUCCESS       = 'CATEGORIES_POST_TYPES_LIST_SUCCESS';
export const CATEGORIES_POST_TYPES_LIST_FAIL          = 'CATEGORIES_POST_TYPES_LIST_FAIL';

export const CATEGORY_RESTORE_REQUEST          = 'CATEGORY_RESTORE_REQUEST';
export const CATEGORY_RESTORE_SUCCESS          = 'CATEGORY_RESTORE_SUCCESS';
export const CATEGORY_RESTORE_FAIL             = 'CATEGORY_RESTORE_FAIL';


export const CATEGORY_DESTROY_REQUEST = 'CATEGORY_DESTROY_REQUEST';
export const CATEGORY_DESTROY_SUCCESS = 'CATEGORY_DESTROY_SUCCESS';
export const CATEGORY_DESTROY_FAIL = 'CATEGORY_DESTROY_FAIL';