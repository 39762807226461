const { 
    MENU_ITEMS_ADD_SUCCESS,
    MENU_ITEMS_ADD_REQUEST,
    MENU_ITEMS_SAVE_REQUEST,
    MENU_ITEMS_SAVE_SUCCESS,
    MENU_ITEMS_SAVE_FAIL,
    MENU_ITEMS_LIST_REQUEST,
    MENU_ITEMS_LIST_SUCCESS,
    MENU_ITEMS_LIST_FAIL,
    MENU_ID_SET_SUCCESS,
    MENU_Lang_ID_SET_SUCCESS
} = require("../constants/menuItemsConstants");

function setMenuItemsReducer(state = { menuItems: [], loading: false }, action) {
    
    switch (action.type) {
        case  MENU_ITEMS_ADD_REQUEST:
            return { loading: true };
        case MENU_ITEMS_ADD_SUCCESS:
            return { menuItems: action.payload, loading: false };
        default:
            return state;
    }

}

function selectedMenuIdReducer(state = { menuId: ""}, action) {
    
    switch (action.type) {
        case MENU_ID_SET_SUCCESS:
            return { menuId: action.payload };
        default:
            return state;
    }

}

function selectedMenuLangIdReducer(state = { menuLangId: ""}, action) {
    
    switch (action.type) {
        case MENU_Lang_ID_SET_SUCCESS:
            return { menuLangId: action.payload };
        default:
            return state;
    }

}


function MenuItemsListReducer(state = { loading: false, menuItems: [] }, action) {

    switch (action.type) {
        case MENU_ITEMS_LIST_REQUEST:
            return { loading: true };
        case MENU_ITEMS_LIST_SUCCESS:
            return { loading: false, menuItems: action.payload };
        case MENU_ITEMS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function saveMenuItemReducer(state = { menuItems: [] }, action) {
    
    switch(action.type){
        case MENU_ITEMS_SAVE_REQUEST:
            return {loading : true};
        case MENU_ITEMS_SAVE_SUCCESS:
            return {loading: false, success: true, menuItems: action.payload};
        case MENU_ITEMS_SAVE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

export { setMenuItemsReducer, saveMenuItemReducer, MenuItemsListReducer, selectedMenuIdReducer, selectedMenuLangIdReducer}