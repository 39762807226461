import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray} from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import {listRoles, saveRole, deleteRole, selectedRole, toggleRolesSidebar} from '../../actions/rolesActions';

const CreateRolesSchema = Yup.object().shape({
    roles_name: Yup.string().min(3, Errors.min({ name: "le nom", number: "3" })).required(Errors.required({ name: "le nom"}))
})

export default function FormComponent() {

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [rolePermissions, setRolePermissions] = useState([]);

    const [selectAll , setSelectAll] = useState(false);

    const [allCheckboxes , setCheckboxes] = useState([]);

    const [name, setName] = useState('');

    const [saveSuccess, setSaveSuccess] = useState(false);

    const rolesToggleSidebar = useSelector(state => state.toggleRolesSidebar);

    const roleSelected = useSelector(state => state.selectedRole);

    const permissionsList = useSelector(state => state.permissionsList);

    const { permissions, loading: loadingPermissions } = permissionsList;

    const rolesSave = useSelector(state => state.rolesSave);
    const [value, setValue] = useState(0); // integer state
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = rolesSave;

     useEffect(() => {
        if(roleSelected.role){
                setId(roleSelected.role.id);
                setName(roleSelected.role.roles_name);
                let permission = [];
                roleSelected.role.permissions.forEach(p => {
                    permission.push(p.id.toString());
                });
                setRolePermissions(permission)
            }else{
                setId('');
                setName('');
            }
            
            if(saveSuccess){
                setSaveSuccess(false);
                dispatch(listRoles());
                toast.success('Utilisateur Enregistrer');
            }
            if (permissions) {
                let checkboxes = [];
                permissions.forEach(element => {
                    checkboxes.push(false);
                });
                setCheckboxes(checkboxes);
            }
    
    }, [roleSelected, saveSuccess,permissions]);
 
    const closeFormSidebar = () => {
        dispatch( selectedRole(null) );
        dispatch( toggleRolesSidebar(false) );
    }
    
     const  handleSubmit = (values) =>{
         dispatch(saveRole(values ,(response)=>{
            setSaveSuccess(true);
        }));
     }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
    {loadingSave && <div>loading ...</div> }
    {errorSave && <div>{errorSave}</div> }
        <div className={rolesToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
        <div className="card quill-wrapper">
        <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
            <h3 className="card-title contact-title-label">{ id === '' ? "Create New Role" : "Update Role"}</h3>
            <div className="close close-icon" onClick={closeFormSidebar}>
                <i className="material-icons">close</i>
            </div>
        </div>
        <div className="divider"></div> 

        <Formik
            initialValues={{
                id: id ? id : '',
                roles_name: name ? name : '',
                 permissions: rolePermissions,
            }}
            validationSchema={CreateRolesSchema}
            enableReinitialize={true}
            onSubmit={async (values, {resetForm}) => {
                closeFormSidebar();
                values = { ...values, permissions: values.permissions.filter(e => e)};
                setSelectAll(false);
                handleSubmit(values);
                // setRolePermissions([]);
                resetForm({values: ''});
            }}
        >
        {({ values, errors, touched, isSubmitting ,setFieldValue}) => (
                <Form className="edit-contact-item mb-5 mt-5">
                    <div className="row">
                        <div className="input-field col s12">
                            <Field id="roles_name" name="roles_name" type="text" className={errors.roles_name ? "error validate" : "validate"} />
                            <label htmlFor="roles_name" className={name !== '' ? 'active' : ''}>Name</label>
                            {errors.roles_name && touched.roles_name ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.roles_name}</div></small>
                            ) : null}
                        </div>
                        <div className="input-field col s12">
                            <p className="mb-1">
                                <label>
                                    <Field type="checkbox" name="selectAll" checked={selectAll} onChange={(event) => { 
                                        if (event.target.checked) {
                                            for (let index = 0; index < permissions.length; index++) {
                                                 setFieldValue('permissions.'+permissions[index].id.toString(), permissions[index].id.toString());
                                            }
                                        }else{
                                           setFieldValue('permissions', []);
                                        }
                                        selectAllHandler(); 
                                    }

                                } value="all" />
                                    <span>Sélectionner tout</span>
                                </label>
                            </p>
                        </div>
                        {
                            !loadingPermissions? (
                                
                                permissions.length ? (
                                    permissions.map((permission,i) => (
                                        <div className="input-field col s6" key={permission.id}>
                                            <p className="mb-1">
                                                <label>
                                                    <Field type="checkbox" name="permissions" checked={values.permissions.includes(permission.id.toString())}
                                                    
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setFieldValue('permissions.'+permission.id.toString(), permission.id.toString());
                                                        } else {
                                                           let per = values.permissions.filter(item=>{
                                                               return item != permission.id.toString()
                                                           })
                                                           setFieldValue('permissions', per);
                                                        }
                                                        
                                                    }}
                                                    
                                                    value={permission.id.toString()}    />
                                                    <span>{permission.permissions_name}</span>
                                                </label>
                                            </p>
                                        </div>
                                    ))
                                ) : ''
                            ) :''
                        }
                    </div>

                    <div className="card-action pl-0 pr-0 right-align">
                        <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                            {!id? (
                                <span>Ajouter</span>
                                ) : (
                                <span>Modifier</span>
                            )}
                        </button>
                    </div>

                </Form>
            )}

        </Formik>

        </div>
        </div>
        </div>
    </React.Fragment> 
}