const { PATH_IS_CHANGED } = require("../constants/pathConstants");

function locationReducer(state = { location: [] }, action) {

    switch (action.type) {
        case PATH_IS_CHANGED:
            return { path: action.payload };
        default:
            return state;
    }

}

export { locationReducer }