// packages 
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Errors from '../../validations/Errors';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { storeNewLoco, locosList } from '../../actions/locoActions';
const CreateLocoSchema = Yup.object().shape({
    text: Yup.string().required(Errors.required({ name: "Le text" })),
})

export default function AddLocoForm() {

    const dispatch = useDispatch();

    return (
        <div className="card-content">
            <h4 className="card-title">Nouveau text</h4>
            <Formik
                initialValues={{
                    text: '',
                }}
                validationSchema={CreateLocoSchema}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                    dispatch(storeNewLoco(values, (resp) => {
                        if (resp?.message ==="inserted") {
                            toast.success('Text Enregistrer');
                        } else if (resp?.message === "updated") {
                            toast.info('Le texte a été mis à jour');
                        }else{
                            toast.error('Une erreur a été détectée');
                        }
                        dispatch(locosList());
                        resetForm({ values: '' });
                    }))
                }}
            >
                {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="row">
                            <div className="input-field">
                                <Field as="textarea" id="icon_password" className="materialize-textarea" name="text" ></Field>
                                <label htmlFor="icon_password" active={values.text ? "active" : ""}>Text</label>
                            </div>

                            <div className="input-field right">
                                <div className="input-field right">
                                    <button className="btn cyan right" type="submit" name="action" disabled={isSubmitting}>
                                        <i className="material-icons left">save</i> enregistrer
                                </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}

            </Formik>
        </div>
    )
}
