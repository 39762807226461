const { 
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SET_TOKEN_REQUEST,
    SET_TOKEN_SUCCESS,
    IS_LOGGED_IN_REQUEST,
    IS_LOGGED_IN_SUCCESS,
    IS_LOGGED_IN_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    VERIFY_RESET_PASSWORD_LINK_REQUEST,
    VERIFY_RESET_PASSWORD_LINK_SUCCESS,
    VERIFY_RESET_PASSWORD_LINK_FAIL
} = require("../constants/authConstants");


function authReducer(state = { auth: [] }, action) {

    switch (action.type) {
        case LOGIN_REQUEST:
            return { loading: true };
        case LOGIN_SUCCESS:
            return { loading: false, success: true, users: action.payload };
        case LOGIN_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function isLoggedInReducer(state = { isLoggedIn: [] }, action) {

    switch (action.type) {
        case IS_LOGGED_IN_REQUEST:
            return { loading: true };
        case IS_LOGGED_IN_SUCCESS:
            return { loading: false, success: true, user: action.payload };
        case IS_LOGGED_IN_FAIL:
            return { loading: false, user: action.payload };
        default:
            return state;
    }

}



function forgotPasswordReducer(state = { token: [] }, action) {

    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return { loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { loading: false, success: true, user: action.payload };
        case FORGOT_PASSWORD_FAIL:
            return { loading: false, user: action.payload };
        default:
            return state;
    }

}


function verifyResetPasswordLinkReducer(state = { resetUser: [] }, action) {

    switch (action.type) {
        case VERIFY_RESET_PASSWORD_LINK_REQUEST:
            return { loading: true };
        case VERIFY_RESET_PASSWORD_LINK_SUCCESS:
            return { loading: false, success: true, resetUser: action.payload };
        case VERIFY_RESET_PASSWORD_LINK_FAIL:
            return { loading: false, resetUser: action.payload };
        default:
            return state;
    }

}


function resetPasswordReducer(state = { resetPassword: [] }, action) {

    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return { loading: true };
        case RESET_PASSWORD_SUCCESS:
            return { loading: false, success: true, resetPassword: action.payload };
        case RESET_PASSWORD_FAIL:
            return { loading: false, resetPassword: action.payload };
        default:
            return state;
    }

}



export { authReducer, isLoggedInReducer, forgotPasswordReducer, verifyResetPasswordLinkReducer, resetPasswordReducer}