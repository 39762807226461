import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import  classNames  from 'classnames';
import { menuItems } from './MenuData';
import { useSelector, useDispatch } from 'react-redux';
import { setSideBarItems } from '../../../actions/sideBarActions'
export default function MenuItems(){

    const location = useSelector(state => state.location);
    const dispatch = useDispatch();

    const { postType,
        loading,
        error } = useSelector(state => state.postTypeList);
    const { sideBarItems} = useSelector(state => state.sideBarItems);

    const { loading: userAuthPermissionsLoading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    
    const hasPermissions =(key)=>{
        return userAuthPermissions?.find((item)=>{
           return item.key === key || item.key === "ALL"
        })
    }

    useEffect(() => {
        let menuItemsArray = [...menuItems];

        if(postType){

            postType.map((item,i)=>{

                let menuPostTypeItem = {};

                menuPostTypeItem.icon = 'photo_filter';
                menuPostTypeItem.label = item.post_type_title;
                menuPostTypeItem.to = '/posts/'+ item.id;
                menuPostTypeItem.key = 'POST_'+ item.id;

                menuItemsArray.splice(1, 0, menuPostTypeItem);

            });

           
            dispatch(setSideBarItems(menuItemsArray))
            window.initPlugins();

        }

    }, []);
    
    return (
        <React.Fragment>
            
        <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
            {
                sideBarItems.map((item,i)=>{
                    var parentClass = classNames('waves-effect waves-cyan', {
                        'collapsible-header': item.content,
                        'active': location.path === item.to
                    });
                    return hasPermissions(item.key) ?  (
                        <li className="bold" key={'menu-parent'+i}>
                            <Link to={item.to} className={parentClass}>
                                <i className="material-icons">{item.icon}</i>
                                <span className="menu-title" data-i18n="Mail">{item.label}</span>
                            </Link>
                            {item.content ? (
                                <div className="collapsible-body">
                                    <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                        {
                                            item.content.map((contentItem,i)=>{
                                                return hasPermissions(contentItem.key)  ? (
                                                        <li key={'menu-child' + i} className={location.path === contentItem.to ? ("active") : ""}>
                                                            <Link to={contentItem.to} className={location.path === contentItem.to ? ("active") : ""}>
                                                                <i className="material-icons"  >{contentItem.icon}</i>
                                                                <span data-i18n="Modern Menu">{contentItem.label}</span></Link>
                                                        </li>
                                                ) : ""
                                               
                                            }) 
                                        }

                                    </ul>
                                </div>
                            ) : ""}
                        </li>
                    ) : ""
                })
            }
        </ul>
        </React.Fragment>
    );
}
