import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import Users from '../components/Users/index';
import Profile from '../components/Users/Profile/Profile';
import Permissions from '../components/Permissions/index';
import Roles from '../components/Roles/index';
import FilesManager from '../components/FilesManager/index';
import PostType from '../components/PostType/index';
import PostsList from '../components/Posts/PostsList/PostsList';
import PostsForm from '../components/Posts/PostsForm';
import PostTemplates from '../components/PostTemplates/index';
import PostTemplatesForm from '../components/PostTemplates/PostTemplatesForm';
import NotFound from '../components/errors/notFound'
import Header from '../components/Layout/Header'
import SideNav from '../components/Layout/SideNav'
import Footer from '../components/Layout/Footer'
// Categories
import Categories from '../components/categories/Categories'
// Category Items
import CategoryItems from '../components/categoryItems/CategoryItems'
// Languages
import Languages from '../components/languages/Languages'
// Menus
import Menus from '../components/Menus/Menus'
//Menu items
import MenuItems from '../components/MenuItems/MenuItems'
// AuthorizedApps
import AuthorizedApps from '../components/authorizedApps/AuthorizedApps'
// Loco 
import Loco from '../components/LocoTranslate/Loco'

export default function routes() {
    return (
        <React.Fragment>

             <Header />
                <SideNav />
                <div id="main">
                        <Switch>
                            <Route path="/" exact={true} component={Dashboard} />
                            {/* users  */}
                            <Route path="/users" exact={true} component={Users} />
                            <Route path="/permissions" exact={true} component={Permissions} />
                            <Route path="/roles" exact={true} component={Roles} />
                            <Route path="/profile" exact={true} component={Profile} />
                            {/* files  */}
                            <Route path="/files-manager/:filtre?" exact={true} component={FilesManager} />
                            {/* posts  */}
                            <Route path="/post-type/:trush?" exact={true} component={PostType} />
                            <Route path="/posts/:id/save" component={PostsForm} />
                            <Route path="/posts/:trush?/:id" component={PostsList} />
                            {/* categories  */}
                            <Route path="/categories/:trush?" component={Categories} />
                            {/* Menu items  */}
                            <Route path="/category-items/:id?" exact={true} component={CategoryItems} />
                            {/* templates  */}
                            <Route path="/post-templates/:trush?" component={PostTemplates} />
                            <Route path="/post-templates-form" exact={true} component={PostTemplatesForm} />
                            {/* languages */}
                            <Route path="/languages/:trush?" exact={true} component={Languages} />
                            {/* Loco translate */}
                            <Route path="/loco-translate" exact={true} component={Loco} />
                            {/* Menus */}
                            <Route path="/menus" exact={true} component={Menus} />
                            {/* Menu items  */}
                            <Route path="/menu-items/:id?" exact={true} component={MenuItems} />
                            {/* Authorized apps  */}
                            <Route path="/authorized-apps/:trush?" exact={true} component={AuthorizedApps} />
                            {/* 404 */}
                            <Route component={NotFound} />
                        </Switch> 
                </div>
            <Footer />

        </React.Fragment>
        
    )
}
