const { 
    POSTTEMPLATES_LIST_REQUEST,
    POSTTEMPLATES_LIST_SUCCESS,
    POSTTEMPLATES_LIST_FAIL,
    POSTTEMPLATES_SAVE_REQUEST,
    POSTTEMPLATES_SAVE_SUCCESS,
    POSTTEMPLATES_SAVE_FAIL,
    POSTTEMPLATES_DELETE_REQUEST,
    POSTTEMPLATES_DELETE_SUCCESS,
    POSTTEMPLATES_DELETE_FAIL,
    SELECTED_POSTTEMPLATE,
    POSTTEMPLATES_SIDEBAR_IS_TOGGLED,
     POST_TEMPLATE_RESTORE_REQUEST,
    POST_TEMPLATE_RESTORE_SUCCESS,
    POST_TEMPLATE_RESTORE_FAIL,
    POST_TEMPLATE_DESTROY_REQUEST,
    POST_TEMPLATE_DESTROY_SUCCESS,
    POST_TEMPLATE_DESTROY_FAIL

} = require("../constants/postTemplatesConstants");

function postTemplatesListReducer(state = { loading: true, PostTemplates: [] }, action) {

    switch (action.type) {
        case POSTTEMPLATES_LIST_REQUEST:
            return { loading: true };
        case POSTTEMPLATES_LIST_SUCCESS:
            return { loading: false, postTemplates: action.payload };
        case POSTTEMPLATES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postTemplatesSaveReducer(state = { postTemplates: [] }, action) {

    switch (action.type) {
        case POSTTEMPLATES_SAVE_REQUEST:
            return { loading: true };
        case POSTTEMPLATES_SAVE_SUCCESS:
            return { loading: false, success: true, postTemplates: action.payload };
        case POSTTEMPLATES_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postTemplatesRestoreReducer(state = { template: [] }, action) {

    switch (action.type) {
        case POST_TEMPLATE_RESTORE_REQUEST:
            return { loading: true };
        case POST_TEMPLATE_RESTORE_SUCCESS:
            return { loading: false,  template: action.payload };
        case POST_TEMPLATE_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}



function destroyPostTempateReducer(state = { postTemplate: [] }, action) {

    switch (action.type) {
        case POST_TEMPLATE_DESTROY_REQUEST:
            return { loading: true };
        case POST_TEMPLATE_DESTROY_SUCCESS:
            return { loading: false, success: true, postTemplate: action.payload };
        case POST_TEMPLATE_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postTemplatesDeleteReducer(state = { postTemplates: [] }, action) {

    switch (action.type) {
        case POSTTEMPLATES_DELETE_REQUEST:
            return { loading: true };
        case POSTTEMPLATES_DELETE_SUCCESS:
            return { loading: false, success: true, postTemplates: action.payload };
        case POSTTEMPLATES_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedPostTemplateReducer(state = { postTemplates: null }, action) {

    switch (action.type) {
        case SELECTED_POSTTEMPLATE:
            return { postTemplates: action.payload };
        default:
            return state;
    }

}

export { postTemplatesListReducer, selectedPostTemplateReducer, postTemplatesSaveReducer, postTemplatesDeleteReducer, postTemplatesRestoreReducer, destroyPostTempateReducer}