import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function PostTemplatesCount() {

    const postTemplatesList = useSelector(state => state.postTemplatesList);
    const { postTemplates,
        loading,
        error } = postTemplatesList;

    useEffect(() => {

    }, [postTemplates]);

    return (
        <React.Fragment>
            {loading ? <div>Loading...</div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{postTemplates.length} Post Templates</p>
            }
        </React.Fragment>
    );
}