import React, { useEffect, useState } from 'react'

// packages 
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify'
import { Link} from 'react-router-dom'

// actions 
import { listMenuItems, setMenuItems, setMenuId, setMenuLangId } from '../../actions/MenuItemsActions';
import { listLanguages } from '../../actions/languagesActions';
import { listMenus } from '../../actions/menusActions';
import { pathListner } from '../../actions/pathActions';
import Loading from '../loader/Loading'

// components 
import MenuItemTypeSideBar from './MenuItemTypeSideBar'
import MenuItemsZone from './MenuItemsZone'

export default function MenuItems(props) {

        const dispatch = useDispatch();
        const [loading, setLoading] = useState(true);
        const [hasMenus, setHasMenus] = useState(false);


       
        useEffect(() => {
           
            dispatch(listMenus((menus)=>{
                if (menus.length) {
                    setHasMenus(true)
                    if (props.match.params.id) {
                        dispatch(setMenuId(props.match.params.id));
                        /**  listMenuItems
                        * @param {menu_id int}  props.match.params.i
                        *  @param {lang_id int}  1 remove 1 and set the default dynamic language
                        **/
                        dispatch(listLanguages((langs)=>{
                            dispatch(setMenuLangId(langs[0].id));
                            dispatch(listMenuItems(props.match.params.id, langs[0].id, (menusListItem) => {
                                const items = menusListItem.items ? menusListItem.items : [];
                                dispatch(setMenuItems(items))
                                setLoading(false)
                            }));
                        }));
                       
                    } else {
                        dispatch(setMenuId(menus[0].id));
                        dispatch(listLanguages((langs) => {
                            dispatch(setMenuLangId(langs[0].id));
                            dispatch(listMenuItems(menus[0].id, langs[0].id, (menusListItem) => {
                                const items = menusListItem.items ? menusListItem.items : [];
                                dispatch(setMenuItems(items))
                                setLoading(false)
                            }));
                        }));
                        
                    }
                }else{
                    setLoading(false)
                }
            }));
            dispatch(pathListner('/menu-items'));
        }, [])
    
        return (
  
        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    <section className="tabs-vertical mt-1 section">
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            {
                                loading ? (
                                    <Loading/>
                                ) : (
                                    hasMenus? (
                                        <div className="row container">
                                            <div className="col l4 s12">
                                                <MenuItemTypeSideBar props={props} />
                                            </div>
                                            <div className="col l8 s12">
                                                <MenuItemsZone />
                                            </div>
                                        </div>
                                    ): (
                                         <div className="row container">
                                                    <div className="card-panel grey darken-4 accent-1 center  l12 pt-5 pb-5 mb-3 mt-3  ml-3 mr-3">
                                                    <span > 
                                                    <h4 className="white-text">Aucun Menu trouvé</h4>
                                                    <Link to="/menus" className="btn-small mb-3 mt-3 gradient-45deg-indigo-light-blue">
                                                        <i className="material-icons left">add_circle</i>Ajouter menu</Link></span>
                                                </div>
                                        </div>
                                       
                                    )
                                    
                                )
                            }
                           
                        
                            

                    </section>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
        )
    
}
