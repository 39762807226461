const { LANGUAGES_LIST_REQUEST,
    LANGUAGES_LIST_SUCCESS,
    LANGUAGES_LIST_FAIL,
    LANGUAGES_SAVE_REQUEST,
    CURRENT_LANGUAGE_REQUEST,
    CURRENT_LANGUAGE_SUCCESS,
    CURRENT_LANGUAGE_FAIL,
    LANGUAGES_SAVE_SUCCESS,
    LANGUAGES_SAVE_FAIL,
    LANGUAGES_DELETE_REQUEST,
    LANGUAGES_DELETE_SUCCESS,
    LANGUAGES_DELETE_FAIL,
    SELECTED_LANGUAGE,
    LANGUAGES_SIDEBAR_IS_TOGGLED,
    LANGUAGES_RESTORE_REQUEST,
    LANGUAGES_RESTORE_SUCCESS,
    LANGUAGES_RESTORE_FAIL,
    LANGUAGES_DESTROY_REQUEST,
    LANGUAGES_DESTROY_SUCCESS,
    LANGUAGES_DESTROY_FAIL 
 } = require("../constants/languagesConstants");

function languagesListReducer(state = { loading: true, languages: [] }, action) {

    switch (action.type) {
        case LANGUAGES_LIST_REQUEST:
            return { loading: true };
        case LANGUAGES_LIST_SUCCESS:
            return { loading: false, languages: action.payload };
        case LANGUAGES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function languagesSaveReducer(state = { languages: [] }, action) {

    switch (action.type) {
        case LANGUAGES_SAVE_REQUEST:
            return { loading: true };
        case LANGUAGES_SAVE_SUCCESS:
            return { loading: false, success: true, languages: action.payload };
        case LANGUAGES_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function currentLanguageReducer(state = { currentLang: [] }, action) {

    switch (action.type) {
        case CURRENT_LANGUAGE_REQUEST:
            return { loading: true };
        case CURRENT_LANGUAGE_SUCCESS:
            return { loading: false, success: true, currentLang: action.payload };
        case CURRENT_LANGUAGE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function languagesDeleteReducer(state = { language: [] }, action) {

    switch (action.type) {
        case LANGUAGES_DELETE_REQUEST:
            return { loading: true };
        case LANGUAGES_DELETE_SUCCESS:
            return { loading: false, success: true, language: action.payload };
        case LANGUAGES_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function languagesRestoreReducer(state = { language: [] }, action) {

    switch (action.type) {
        case LANGUAGES_RESTORE_REQUEST:
            return { loading: true };
        case LANGUAGES_RESTORE_SUCCESS:
            return { loading: false, success: true, language: action.payload };
        case LANGUAGES_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function destroyLanguageReducer(state = { language: [] }, action) {

    switch (action.type) {
        case  LANGUAGES_DESTROY_REQUEST:
            return { loading: true };
        case  LANGUAGES_DESTROY_SUCCESS:
            return { loading: false, success: true, language: action.payload };
        case  LANGUAGES_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedLanguageReducer(state = { language: null }, action) {

    switch (action.type) {
        case SELECTED_LANGUAGE:
            return { language: action.payload };
        default:
            return state;
    }

}

function toggleLanguagesSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case LANGUAGES_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { languagesListReducer, languagesSaveReducer, languagesDeleteReducer, selectedLanguageReducer, toggleLanguagesSidebarReducer, currentLanguageReducer, languagesRestoreReducer, destroyLanguageReducer }