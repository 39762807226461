export const POSTTEMPLATES_LIST_REQUEST = 'POSTTEMPLATES_LIST_REQUEST';
export const POSTTEMPLATES_LIST_SUCCESS = 'POSTTEMPLATES_LIST_SUCCESS';
export const POSTTEMPLATES_LIST_FAIL = 'POSTTEMPLATES_LIST_FAIL';

export const POSTTEMPLATES_SAVE_REQUEST = 'POSTTEMPLATES_SAVE_REQUEST';
export const POSTTEMPLATES_SAVE_SUCCESS = 'POSTTEMPLATES_SAVE_SUCCESS';
export const POSTTEMPLATES_SAVE_FAIL = 'POSTTEMPLATES_SAVE_FAIL';

export const POSTTEMPLATES_DELETE_REQUEST = 'POSTTEMPLATES_DELETE_REQUEST';
export const POSTTEMPLATES_DELETE_SUCCESS = 'POSTTEMPLATES_DELETE_SUCCESS';
export const POSTTEMPLATES_DELETE_FAIL = 'POSTTEMPLATES_DELETE_FAIL';

export const SELECTED_POSTTEMPLATE = 'SELECTED_POSTTEMPLATE';


export const POST_TEMPLATE_RESTORE_REQUEST = 'POST_TEMPLATE_RESTORE_REQUEST';
export const POST_TEMPLATE_RESTORE_SUCCESS = 'POST_TEMPLATE_RESTORE_SUCCESS';
export const POST_TEMPLATE_RESTORE_FAIL    = 'POST_TEMPLATE_RESTORE_FAIL';

export const POST_TEMPLATE_DESTROY_REQUEST = 'POST_TEMPLATE_DESTROY_REQUEST';
export const POST_TEMPLATE_DESTROY_SUCCESS = 'POST_TEMPLATE_DESTROY_SUCCESS';
export const POST_TEMPLATE_DESTROY_FAIL = 'POST_TEMPLATE_DESTROY_FAIL';