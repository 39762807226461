import React, { useEffect, useState } from 'react'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { verifyResetPasswordLink, resetPassword } from '../../../actions/authActions'
import Errors from '../../../validations/Errors'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton';
import { useHistory } from "react-router-dom";


const forgotPasswordSchema = Yup.object().shape({
    users_password: Yup.string().min(8, Errors.min({ name: "le mot de passe", number: "8" })).required(Errors.required({ name: "le mot de passe" })),
    confirm_users_password: Yup
    .string()
    .min(8, Errors.min({ name: "le mot de passe", number: "8" }))
    .required(Errors.required({ name: "le mot de passe" }))
    .when("users_password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("users_password")],
            Errors.confirmPassword()
        )
    }),
})


export default function ResetPasswordForm({props}) {

    const dispatch = useDispatch();
    let history = useHistory();

    const [alowUserToReset, setAlowUserToReset] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const { loading, user, success } = useSelector(state => state.verifyResetPasswordLink);



    useEffect(() => {
        dispatch(verifyResetPasswordLink({
            "iv": props.match.params.iv,
            "content": props.match.params.content
        },(resp)=>{
            if (!resp.error) {
                setUserEmail(resp.data.user.users_email)
                setAlowUserToReset(resp.data.alowUserToReset);
            }else{
                setAlowUserToReset(resp.data.alowUserToReset);
            }
        }));
    }, [alowUserToReset]);


    return (
        !alowUserToReset ? (
            <div className="card-panel gradient-45deg-red-pink gradient-shadow">
                <div className="card-content white-text center">
                    <span className="card-title"><i className="material-icons">sentiment_dissatisfied</i></span>
                    <p>
                        ce lien n'est pas valide
                    </p>
                </div>
            </div>
        ) : (

                <Formik
                    initialValues={{
                        users_password:  '',
                        confirm_users_password: '',
                        email: userEmail ? userEmail : null
                    }}
                    validationSchema={forgotPasswordSchema}
                    enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        dispatch(resetPassword(values,(res)=>{
                            if (!res.error) {
                                toast.success(res.data.message);
                                setTimeout(() => {
                                    history.push("/login");
                                }, 1200);
                            }else{
                                toast.success(res.data.message);
                            }
                        }))
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (

                        loading ? (
                            <div className="pt-5 pb-5 pl-5 pr-5">
                                <Skeleton count={5} />
                            </div>
                        ) : (
                                <Form className="login-form">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <h5 className="ml-4">Mot de passe oublié</h5>
                                            <p className="ml-4">Vous pouvez réinitialiser votre mot de passe</p>
                                        </div>
                                    </div>
                                    <div className="row margin">
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix pt-2">lock_outline</i>
                                            <Field id="users_password" name="users_password" type="password" className={errors.users_password ? "error validate" : "validate"} />
                                            <label htmlFor="users_password" >Votre nouveau mot de passe</label>
                                            {errors.users_password && touched.users_password ? (
                                                <small className="errorTxt2"><div id="users_password_error" className="error">{errors.users_password}</div></small>
                                            ) : null}
                                        </div>
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix pt-2">lock_outline</i>
                                            <Field id="confirm_users_password" name="confirm_users_password" type="password" className={errors.confirm_users_password ? "error validate" : "validate"} />
                                            <label htmlFor="confirm_users_password" >Confirmez votre mot de passe</label>
                                            {errors.confirm_users_password && touched.confirm_users_password ? (
                                                <small className="errorTxt2"><div id="confirm_users_password_error" className="error">{errors.confirm_users_password}</div></small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <button type="submit" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" disabled={isSubmitting}>Réinitialiser</button>
                                        </div>
                                    </div>
                                </Form>
                            )
                    )}
                </Formik>
            )
    )
}
