import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pathListner } from '../../actions/pathActions';
import {listPostType, selectedPostType, togglePostTypeSidebar} from '../../actions/postTypeActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PostTypeFiltre from './PostTypeFiltre';
import PostTypeDatatable from './PostTypeDatatable';
import PostTypeFormSidebar from './PostTypeFormSidebar';

export default function PostType(props) {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(pathListner('/post-type'));
        dispatch(listPostType());
        dispatch(selectedPostType(null));
        dispatch(togglePostTypeSidebar(false));

    }, []);

    const addPostType = () => {
        dispatch(selectedPostType(null));
        dispatch(togglePostTypeSidebar(true));
    }

    return <React.Fragment>

    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />  

    <div className="row">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
        <div className="col s12">
            <div className="container">

                <div className="contact-overlay"></div>
                <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                    <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addPostType}>
                        <i className="material-icons">add_box</i>
                    </a>
                </div>

                <PostTypeFiltre props={props}/>

                    <PostTypeDatatable props={props}/>

                <PostTypeFormSidebar />

            </div>
            <div className="content-overlay"></div>
        </div>
    </div>
    
    </React.Fragment>;
}
