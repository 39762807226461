import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { 
    POSTTEMPLATES_LIST_REQUEST,
    POSTTEMPLATES_LIST_SUCCESS,
    POSTTEMPLATES_LIST_FAIL,
    POSTTEMPLATES_SAVE_REQUEST,
    POSTTEMPLATES_SAVE_SUCCESS,
    POSTTEMPLATES_SAVE_FAIL,
    POSTTEMPLATES_DELETE_REQUEST,
    POSTTEMPLATES_DELETE_SUCCESS,
    POSTTEMPLATES_DELETE_FAIL,
    SELECTED_POSTTEMPLATE,
    POST_TEMPLATE_RESTORE_REQUEST,
    POST_TEMPLATE_RESTORE_SUCCESS,
    POST_TEMPLATE_RESTORE_FAIL,
    POST_TEMPLATE_DESTROY_REQUEST,
    POST_TEMPLATE_DESTROY_SUCCESS,
    POST_TEMPLATE_DESTROY_FAIL
} = require("../constants/postTemplatesConstants");

const listPostTemplates = (cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTTEMPLATES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/post_templates",
            headers,
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTTEMPLATES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTEMPLATES_LIST_FAIL, payload: error.message });
    }

}

const listTrashedTemplates = (cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTTEMPLATES_LIST_REQUEST });
        const { data } = await axios.get({
            method: 'get',
            url: apiProxy+"/api/post_templates/trashed",
            headers,
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTTEMPLATES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTEMPLATES_LIST_FAIL, payload: error.message });
    }

}

const restorePostTemplates = (template, callback) => async (dispatch) => {
    try {
        dispatch({ type: POST_TEMPLATE_RESTORE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy+"/api/post_templates/restore/" + template.id,
            headers,
        });
        callback(data);
        dispatch({ type:  POST_TEMPLATE_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type:  POST_TEMPLATE_RESTORE_FAIL, payload: error.message });
    }

}


const destroyPostTempate = (template, callback) => async (dispatch) => {
    try {
        dispatch({ type: POST_TEMPLATE_DESTROY_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/post_templates/destroy/" + template.id,
            headers,
        });
        callback(data);
        dispatch({ type: POST_TEMPLATE_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POST_TEMPLATE_DESTROY_FAIL, payload: error.message });
    }

}

const savePostTemplates = (postTemplate, callback) => async (dispatch) => {

    try {
        if (!postTemplate.id) {
            dispatch({ type: POSTTEMPLATES_SAVE_REQUEST, payload: { postTemplate } });
            const { data } = await axios({
                method: 'post',
                url: apiProxy+"/api/post_templates",
                headers,
                data: postTemplate
            });
            callback(data);
            dispatch({ type: POSTTEMPLATES_SAVE_SUCCESS, payload: data });
        } else {
            dispatch({ type: POSTTEMPLATES_SAVE_REQUEST, payload: { postTemplate } });
            const { data } = await axios({
                method: 'patch',
                url: apiProxy+"/api/post_templates/" + postTemplate.id,
                headers,
                data: postTemplate
            });
            callback(data);
            dispatch({ type: POSTTEMPLATES_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: POSTTEMPLATES_SAVE_FAIL, payload: error.message });
    }

}

const deletePostTemplates = (postTemplate, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTTEMPLATES_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/post_templates/" + postTemplate.id,
            headers,
        });
        callback(data);
        dispatch({ type: POSTTEMPLATES_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTEMPLATES_DELETE_FAIL, payload: error.message });
    }

}

const selectedPostTemplate = (postTemplate) => async (dispatch) => {
    dispatch({ type: SELECTED_POSTTEMPLATE, payload: postTemplate });
}

export { listPostTemplates, selectedPostTemplate, deletePostTemplates, savePostTemplates, listTrashedTemplates, restorePostTemplates, destroyPostTempate}