import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listCategories, deleteCategory, selectedCategory, toggleCategoriesSidebar, restoreCategory, destroyCategory} from '../../actions/categoriesActions';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";


export default function CategoriesDatatable() {

    const dispatch = useDispatch();
    let history = useHistory();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const categoriesList = useSelector(state => state.categories);
    const { categories, 
            loading,
            error } = categoriesList;
    const categoryDelete = useSelector(state => state.categoryDelete);
    const { loading: loadingDelete,
            success: successDelete,
        error: errorDelete } = categoryDelete;


    const columns = [
        {
            name: 'Titre',
            selector: 'categories_title',
            sortable: true,
        },
        {
            name: 'Slug',
            selector: 'categories_slug',
            sortable: true,
        },
        {
            name: 'Les element',
            ignoreRowClick: true,
            allowOverflow: true,
            cell: (category) =>  {
                let dataTip = "";
                for (let i = 0; i < category?.items?.length; i++) {
                    let title = category.items[i].cat_item_title;
                    if (i!= category.items.length-1) {
                        title+= ' , '
                    }
                    dataTip += title;
                }
                return category?.items?.length ? <a href="" data-for="linked_posts" data-iscapture="true" data-tip={dataTip}>
                <p>{category?.items[0]?.cat_item_title + '...'}</p>
                <ReactTooltip place="bottom" id="linked_posts" type="dark" effect="solid" />
            </a> : "aucun element"
            }
                
        },
        {

            cell: (cat) => !cat.deleted_at ?<Link to={"/category-items/" + cat.id_cat} data-for="catItem_link" data-iscapture="true" data-tip="Add items to this category">
                <i className="material-icons">add_box</i>
                <ReactTooltip place="bottom" id="catItem_link" type="dark" effect="solid" />
            </Link>:"",
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (category) => !category.deleted_at ? 
                <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => {  editCategoryHandler(category); } }>
                    <i className="material-icons">edit</i>
                </button> : 
                <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyCategoryHandler(category); }}>
                    <i className="material-icons" style={{ color: "#d32f2f" }}>delete_forever</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( category ) =>
                !category.deleted_at ? 
                <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deleteCategoryHandler(category); }}>
                    <i className="material-icons">delete</i>
                </button> : 
                <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restoreCategoryHandler(category); }}>
                    <i className="material-icons">restore</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(categories);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listCategories());
        }

    }, [ deleteSuccess, categories]);

    const editCategoryHandler = ( categoryData ) => {
        dispatch(selectedCategory(categoryData));
        dispatch(toggleCategoriesSidebar(true));
    }

    const destroyCategoryHandler = (category) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'supprimer définitivement'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyCategory(category, (response) => {
                    history.push('/categories')
                    dispatch(listCategories());
                    Swal.fire(
                        'supprimer définitivement',
                        'Categorie bien supprimer.',
                        'success'
                    )
                }));

            }
        })

    }

    const restoreCategoryHandler = (category) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Restaurer'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restoreCategory(category, (response) => {
                    dispatch(listCategories());
                    history.push('/categories')
                    Swal.fire(
                        'Restaurer',
                        'Le categorie bien Restaurer.',
                        'success'
                    )
                }));

            }
        })

    }

    const deleteCategoryHandler = ( categoryData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteCategory(categoryData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Category bien supprimer.',
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = categories.filter(item => item.categories_title && item.categories_title.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div>loading ...</div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

