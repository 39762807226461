import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { pathListner } from '../../../actions/pathActions';

import { listPosts, selectedPost, listTrashedPosts } from '../../../actions/postsActions';
import PostsListFiltre from './PostsListFiltre';
import PostsListDatatable from './PostsListDatatable';

import { listPostTemplates } from '../../../actions/postTemplatesActions';

import { listLanguages, currentLanguage} from '../../../actions/languagesActions';

export default function PostsList(props) {

    const dispatch = useDispatch();

    const [postData, setPostData] = useState({});

    const postTypeList = useSelector(state => state.postTypeList);
    const { postType } = postTypeList;

    useEffect(() => {

        let post_type_id = props.match.params.id;

        dispatch(pathListner('/posts/'+ post_type_id));
        dispatch(selectedPost(null));
        
        dispatch(listPostTemplates());
        
        if (typeof props.match.params.trush != "undefined") {
            dispatch(listTrashedPosts(post_type_id));
        } else {
            dispatch(listPosts(post_type_id));
        }

        dispatch(currentLanguage());
        dispatch(listLanguages());

        let postType_data;

        if(postType){
            postType_data = postType.filter(item => item.id === post_type_id);
        }

        if(postType_data){
            setPostData( postType_data[0] );
        }
        

    }, [props]);


    return <React.Fragment>

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                    <div style={{bottom: '54px' , right: '19px'}} className="fixed-action-btn direction-top">
                        <Link to={"/posts/" + props.match.params.id + "/save"} className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" >
                            <i className="material-icons">add_box</i>
                        </Link>
                    </div>
                    {/* <!-- Add new User popup Ends--> */}

                    <PostsListFiltre props={props} post_data={postData} postTypeId={props.match.params.id}/>

                    <PostsListDatatable postTypeId={props.match.params.id}/>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
        
        </React.Fragment>;
}

