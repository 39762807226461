const { 
    PERMISSIONS_LIST_REQUEST,
PERMISSIONS_LIST_SUCCESS,
PERMISSIONS_LIST_FAIL,
PERMISSIONS_SAVE_REQUEST,
PERMISSIONS_SAVE_SUCCESS,
PERMISSIONS_SAVE_FAIL,
PERMISSIONS_DELETE_REQUEST,
PERMISSIONS_DELETE_SUCCESS,
PERMISSIONS_DELETE_FAIL,
SELECTED_PERMISSION,
PERMISSIONS_SIDEBAR_IS_TOGGLED} = require("../constants/permissionsConstants");

function permissionsListReducer(state = { loading: true, permissions: [] }, action) {

    switch (action.type) {
        case PERMISSIONS_LIST_REQUEST:
            return { loading: true };
        case PERMISSIONS_LIST_SUCCESS:
            return { loading: false, permissions: action.payload };
        case PERMISSIONS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function permissionsSaveReducer(state = { permissions: [] }, action) {

    switch (action.type) {
        case PERMISSIONS_SAVE_REQUEST:
            return { loading: true };
        case PERMISSIONS_SAVE_SUCCESS:
            return { loading: false, success: true, permissions: action.payload };
        case PERMISSIONS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function permissionsDeleteReducer(state = { permissions: [] }, action) {

    switch (action.type) {
        case PERMISSIONS_DELETE_REQUEST:
            return { loading: true };
        case PERMISSIONS_DELETE_SUCCESS:
            return { loading: false, success: true, permissions: action.payload };
        case PERMISSIONS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedPermissionsReducer(state = { permission: null }, action) {

    switch (action.type) {
        case SELECTED_PERMISSION:
            return { permission: action.payload };
        default:
            return state;
    }

}

function togglePermissionsSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case PERMISSIONS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export {
    permissionsListReducer, selectedPermissionsReducer, togglePermissionsSidebarReducer, permissionsSaveReducer,
permissionsDeleteReducer }