import React from 'react';
import CategoriesCount from './CategoriesCount';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { listCategories, trashedCategories } from '../../actions/categoriesActions';

export default function CategoriesFiltre( {props} ) {
    let history = useHistory();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                        <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">view_list</i> Categories
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">Number De Categories</p>
                        <CategoriesCount />
                </div>
                </div>
            </div>
            <div id="sidebar-list" className="sidebar-menu list-group position-relative animate fadeLeft delay-1 ps ps--active-y">
                <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                    <ul className="contact-list display-grid">
                        <li className="sidebar-title">Filters</li>

                        <li className={typeof props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                            dispatch(listCategories(() => {
                                history.push('/categories')
                            }))
                        }}><a className="text-sub" ><i className="material-icons mr-2">
                            photo_filter </i> Tout les Template</a>
                        </li>

                        <li className={typeof props.match.params.trush !== "undefined" ? "active" : ""} onClick={() => {
                                dispatch(trashedCategories(() => {
                                history.push('/categories/trush')
                            }))
                        }}><a className="text-sub" ><i className="material-icons mr-2">
                            delete_sweep </i> Corbeille</a>
                        </li>

                    </ul>
                </div>
            </div>
            <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                className="material-icons">menu</i></a>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}