const {
    CATEGORY_ITEMS_ADD_SUCCESS,
    CATEGORY_ITEMS_SAVE_REQUEST,
    CATEGORY_ITEMS_SAVE_SUCCESS,
    CATEGORY_ITEMS_SAVE_FAIL,
    CATEGORY_ITEMS_LIST_REQUEST,
    CATEGORY_ITEMS_LIST_SUCCESS,
    CATEGORY_ITEMS_LIST_FAIL,
    CATEGORY_ITEM_REQUEST,
    CATEGORY_ITEM_SUCCESS,
    CATEGORY_ITEM_FAIL,
    CATEGORY_DELETE_ITEM_REQUEST,
    CATEGORY_DELETE_ITEM_SUCCESS,
    CATEGORY_DELETE_ITEM_FAIL,
    CATEGORY_ID_SET_SUCCESS,
    CATEGORY_LANG_ID_SET_SUCCESS
} = require("../constants/categoryItemsConstants");

function setCategoryItemsReducer(state = { categoryItems: []}, action) {

    switch (action.type) {
        case CATEGORY_ITEMS_ADD_SUCCESS:
            return { categoryItems: action.payload };
        default:
            return state;
    }

}

function selectedCategoryIdReducer(state = { categoryId: "" }, action) {

    switch (action.type) {
        case CATEGORY_ID_SET_SUCCESS:
            return { categoryId: action.payload };
        default:
            return state;
    }

}

function selectedCategoryLangIdReducer(state = { categoryLangId: "" }, action) {

    switch (action.type) {
        case CATEGORY_LANG_ID_SET_SUCCESS:
            return { categoryLangId: action.payload };
        default:
            return state;
    }

}


function findCategoryItemReducer(state = { loading: false, categoryItem: [] }, action) {

    switch (action.type) {
        case CATEGORY_ITEM_REQUEST:
            return { loading: true };
        case CATEGORY_ITEM_SUCCESS:
            return { loading: false, categoryItem: action.payload };
        case CATEGORY_ITEM_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function deleteCategoryItemReducer(state = { category_item: [] }, action) {

    switch (action.type) {
        case CATEGORY_DELETE_ITEM_REQUEST:
            return { loading: true };
        case CATEGORY_DELETE_ITEM_SUCCESS:
            return { loading: false, success: true, category_item: action.payload };
        case CATEGORY_DELETE_ITEM_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function categoryItemsListReducer(state = { loading: false, categoryItems: [] }, action) {

    switch (action.type) {
        case CATEGORY_ITEMS_LIST_REQUEST:
            return { loading: true };
        case CATEGORY_ITEMS_LIST_SUCCESS:
            return { loading: false, categoryItems: action.payload };
        case CATEGORY_ITEMS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function saveCategoryItemReducer(state = { categoryItems: [] }, action) {

    switch (action.type) {
        case CATEGORY_ITEMS_SAVE_REQUEST:
            return { loading: true };
        case CATEGORY_ITEMS_SAVE_SUCCESS:
            return { loading: false, success: true, categoryItems: action.payload };
        case CATEGORY_ITEMS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

export { setCategoryItemsReducer, saveCategoryItemReducer, categoryItemsListReducer, selectedCategoryIdReducer, selectedCategoryLangIdReducer, findCategoryItemReducer, deleteCategoryItemReducer }