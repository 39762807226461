import React from 'react';
import LanguagesCount from './LanguagesCount';
import { listTrashedLanguages, listLanguages } from '../../actions/languagesActions';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
export default function LanguagesFiltre({props}) {
    const dispatch = useDispatch();
    let history = useHistory();
    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                    <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">translate</i> Languages
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">Number de langues</p>
                    <LanguagesCount />
                </div>
                </div>
            </div>
            <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                <ul className="contact-list display-grid">
                    <li className="sidebar-title">Filters</li>

                    <li className={typeof props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                        dispatch(listLanguages(() => {
                            history.push('/languages')
                        }))
                        }}><a className="text-sub"><i className="material-icons mr-2">
                        language </i> Tout les langues</a>
                    </li>

                    <li className={typeof props.match.params.trush !== "undefined" ? "active" : ""} onClick={() => {
                        dispatch(listTrashedLanguages(() => {
                            history.push('/languages/trush')
                        }))
                    }}><a className="text-sub"><i className="material-icons mr-2">
                        delete_sweep </i> Corbeille</a>
                    </li>
                    
                </ul>
                </div>
            </div>
            <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                className="material-icons">menu</i></a>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}