import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Field, Formik } from 'formik'
import * as yup from 'yup'
import { resetPassword } from '../../../actions/authActions'
import Errors from '../../../validations/Errors'
import { toast } from 'react-toastify'

export default function UpdatePasswordForm() {

    const dispatch = useDispatch();
    const { loading, user } = useSelector(state => state.login);
    const forgotPasswordSchema = yup.object().shape({
        users_password: yup.string().min(8, Errors.min({ name: "le mot de passe", number: "8" })).required(Errors.required({ name: "le mot de passe" })),
        confirm_users_password: yup
            .string()
            .min(8, Errors.min({ name: "le mot de passe", number: "8" }))
            .required(Errors.required({ name: "le mot de passe" }))
            .when("users_password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                    [yup.ref("users_password")],
                    Errors.confirmPassword()
                )
            }),
    })

    const [email, setEmail] = useState('');

    useEffect(() => {
        if (user) {
            const authUser = user.token.authUser;
            setEmail(authUser.users_email)
        }

       
    }, [user])
    return (
        <Formik
            initialValues={{
                users_password: '',
                confirm_users_password: '',
                email: email
            }}
            validationSchema={forgotPasswordSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                dispatch(resetPassword(values, (res) => {
                    if (!res.error) {
                        toast.success(res.data.message);
                        resetForm({ values: '' });
                    } else {
                        toast.success(res.data.message);
                    }
                }))
            }}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form id="infotabForm">
                    <div className="row">
                        <div className="col s6 input-field">
                            <i className="material-icons prefix pt-2">lock_outline</i>
                            <Field id="users_password" name="users_password" type="password" className={errors.users_password ? "error validate" : "validate"} />
                            <label htmlFor="users_password" >Votre nouveau mot de passe</label>
                            {errors.users_password && touched.users_password ? (
                                <small className="errorTxt2"><div id="users_password_error" className="error">{errors.users_password}</div></small>
                            ) : null}
                        </div>
                        <div className="col s6 input-field">
                            <i className="material-icons prefix pt-2">lock_outline</i>
                            <Field id="confirm_users_password" name="confirm_users_password" type="password" className={errors.confirm_users_password ? "error validate" : "validate"} />
                            <label htmlFor="confirm_users_password" >Confirmez votre mot de passe</label>
                            {errors.confirm_users_password && touched.confirm_users_password ? (
                                <small className="errorTxt2"><div id="confirm_users_password_error" className="error">{errors.confirm_users_password}</div></small>
                            ) : null}
                        </div>
                    </div>
                    <div className="col s12 display-flex justify-content-end mt-3">
                        <button type="submit" className="btn indigo" disabled={isSubmitting}>
                            modifier</button>
                        <button type="button" className="btn btn-light" >Annuler</button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
