import React from 'react';
import MenusCount from './MenusCount';

export default function MenusFiltre( props ) {

    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                    <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">featured_play_list</i> Menus
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">Number De Menus</p>
                        <MenusCount />
                </div>
                </div>
            </div>
            <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                className="material-icons">menu</i></a>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}