// packages 
import React, { useState,useEffect} from 'react'
import Select from 'react-select'
import { customStyles } from '../../styles/ReactLocoSelect'
import { useSelector, useDispatch } from 'react-redux';
import Errors from '../../validations/Errors';
import Skeleton from 'react-loading-skeleton';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getLocoTranslate, patchLocoTranslate} from '../../actions/locoActions';
const CreateLocoSchema = Yup.object().shape({
    text: Yup.string().required(Errors.required({ name: "Le text" })),
    id_lang: Yup.string().required(Errors.required({ name: "la language" }))
})

export default function LocoTranslateForm() {



    const dispatch = useDispatch();
    const languagesList = useSelector(state => state.languagesList)
    const { locos, loading: locosLoading, error: locosError} = useSelector(state => state.listLocos)
    const { currentLang, loading: currentLangLoading, error: currentLangError } = useSelector(state => state.currentLang)
    const { languages, loading: langsLoading,error : langsError } = languagesList;
    const [lang,setLang] = useState(null)
    const [text,setText] = useState(null)
    const [id,setId] = useState(null)
    const [translate, setTranslate] = useState(null)
    const languageOptions = [];
    const locosOptions = [];

    if (languages) {
         languages.filter(lang => {
            let option = {
                label: lang.langs_title,
                value: lang.id
            }
            languageOptions.push(option);
        })
    }


    if (locos) {
        locos.filter(loco => {
            let option = {
                label: loco.text,
                value: loco.text
            }
            locosOptions.push(option);
        })
    }
    

    const handelLangChange =(lang_id)=>{
        if (text) {
            dispatch(getLocoTranslate({
                "id_lang": lang_id,
                "text": text
            }, (resp) => {
                    setId(resp.id)
                    setTranslate(resp.translate)
            }))
        }
 
    }


    const handelTextChange =(textValue)=>{
        if (lang) {
            dispatch(getLocoTranslate({
                "id_lang": lang,
                "text": textValue
            },(resp)=>{
                    setId(resp.id)
                    setTranslate(resp.translate)
            }))
        }
     
    }

    useEffect(()=>{
        if (currentLang && !lang) {
            setLang(currentLang.id)
        }
    }, [currentLang, translate])

    return (
     
        <div className="card-content">
            <h4 className="card-title">Text traduction</h4>
            <Formik
                initialValues={{
                    id: id ? id : '',
                    text: text ? text : '',
                    translate: translate ? translate :  '',
                    id_lang: lang ? lang : ''
                }}
                    validationSchema={CreateLocoSchema}
                    enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        dispatch(patchLocoTranslate(values, (resp) => {
                            if (resp.id) {
                                toast.success('Traduction Enregistrer');
                            }
                            // resetForm({ values: '' });
                        }))
                    }}
                >
            {({ values, errors, touched, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <label>Language</label>
                        <div className="input-field">
                            {
                                langsLoading && currentLangLoading ? <Skeleton count={1} height={50} /> : (
                                    <Select
                                        name="id_lang"
                                        id="id_lang"
                                        value={languageOptions.filter(opt => { return (opt.value === lang) })}
                                        onChange={(opt, e) => {
                                            setLang(opt?.value)
                                            handelLangChange(opt?.value)
                                        }}
                                        blurInputOnSelect={true}
                                        placeholder=""
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={customStyles}
                                        options={languageOptions}
                                    />
                                )
                            }
                            {errors.id_lang && touched.id_lang ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.id_lang}</div></small>
                            ) : null}
                        </div>
                        <label>Text</label>
                        <div className="input-field">
                                {
                                    locosLoading ? <Skeleton count={1} height={50} /> : (
                                        <Select
                                            name="text"
                                            id="text"
                                            value={locosOptions.filter(opt => { return (opt.label === text) })}
                                            onChange={(opt, e) => {
                                                console.log(opt);
                                                setText(opt?.value)
                                                handelTextChange(opt?.value)
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder=""
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={locosOptions}
                                        />
                                    )
                                }
                                {errors.text && touched.text ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.text}</div></small>
                                ) : null}
                            {/* <label htmlFor="icon_prefix1">Text</label> */}
                        </div>
                        <div className="input-field">
                            <Field as="textarea" id="icon_password" className="materialize-textarea" name="translate" ></Field>
                            <label htmlFor="icon_password" className="active">Traduction</label>
                        </div>
                        
                        <div className="input-field right">
                                <div className="input-field right">
                                    <button className="btn cyan right" type="submit" name="action" disabled={isSubmitting}>
                                        <i className="material-icons left">save</i> enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
                )}

            </Formik>
        </div>
    )
               
}
