import React, { useEffect } from 'react';
import {  useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pathListner } from '../../actions/pathActions';

import { listCategories, selectedCategory, toggleCategoriesSidebar, trashedCategories } from '../../actions/categoriesActions';
import { listPostType } from '../../actions/postTypeActions';
import { listPostTemplates } from '../../actions/postTemplatesActions';

import CategoriesFormSidebar from './CategoriesFormSidebar';
import CategoriesFiltre from './CategoriesFiltre';
import CategoriesDatatable from './CategoriesDatatable';
import { listLanguages, currentLanguage} from '../../actions/languagesActions';

export default function Categories(props) {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(currentLanguage());
        dispatch(listLanguages());
        dispatch(listPostType());
        dispatch(listPostTemplates());

        if (typeof props.match.params.trush != "undefined") {
            dispatch(trashedCategories());
        } else {
            dispatch(listCategories());
        }
        dispatch(pathListner('/categories'));

    }, []);

    const addCategory = () => {
        dispatch(selectedCategory(null));
        dispatch(toggleCategoriesSidebar(true));
    }

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />


        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                    <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                        <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addCategory}>
                            <i className="material-icons">playlist_add</i>
                        </a>
                    </div>
                    {/* <!-- Add new User popup Ends--> */}

                    <CategoriesFiltre props={props} />

                    <CategoriesDatatable />

                    <CategoriesFormSidebar />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

    </React.Fragment>;
}

