import React from 'react';

import PostsListCount from './PostsListCount';
import { listPosts, listTrashedPosts } from '../../../actions/postsActions';

import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
export default function PostsTypeFiltre( props ) {
    const dispatch = useDispatch();
    let history = useHistory();
    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
                <div className="sidebar-content">
                    <div className="sidebar-header">
                        <div className="sidebar-details">
                        <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">photo_filter</i> {props.post_data ? props.post_data.post_type_title : "" }
                        </h5>
                        <div className="mt-10 pt-2">
                            <p className="m-0 subtitle font-weight-700">Number de {props.post_data ? props.post_data.post_type_title : "" }</p>
                            <PostsListCount post_data={props.post_data} />
                        </div>
                        </div>
                    </div>
                        <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                            <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                                <ul className="contact-list display-grid">
                                    <li className="sidebar-title">Filters</li>

                                    <li className={typeof props.props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                                        dispatch(listPosts(props.postTypeId,() => {
                                            history.push('/posts/' + props.postTypeId)
                                        }))
                                    }}><a className="text-sub"><i className="material-icons mr-2">
                                            language </i> Tout les {props.post_data?.post_type_title}</a>
                                    </li>

                                    <li className={typeof props.props.match.params.trush != "undefined" ? "active" : ""} onClick={() => {
                                        dispatch(listTrashedPosts(props.postTypeId,() => {
                                            history.push('/posts/trush/' + props.postTypeId)
                                        }))
                                    }}><a className="text-sub"><i className="material-icons mr-2">
                                        delete_sweep </i> Corbeille</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i className="material-icons">menu</i></a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}