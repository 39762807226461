const { LOCOS_LIST_REQUEST,
    LOCOS_LIST_SUCCESS,
    LOCOS_LIST_FAIL,
    LOCOS_TRANSLATE_REQUEST,
    LOCOS_TRANSLATE_SUCCESS,
    LOCOS_TRANSLATE_FAIL,
    PATCH_LOCOS_TRANSLATE_REQUEST,
    PATCH_LOCOS_TRANSLATE_SUCCESS,
    PATCH_LOCOS_TRANSLATE_FAIL,
} = require("../constants/locosConstants");

function listLocosReducer(state = { loading: false, locos: [] }, action) {

    switch (action.type) {
        case LOCOS_LIST_REQUEST:
            return { loading: true };
        case LOCOS_LIST_SUCCESS:
            return { loading: false, locos: action.payload };
        case LOCOS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function getLocoTranslateReducer(state = { loading: false, locosTranslate: [] }, action) {

    switch (action.type) {
        case LOCOS_TRANSLATE_REQUEST:
            return { loading: true };
        case LOCOS_TRANSLATE_SUCCESS:
            return { loading: false, locosTranslate: action.payload };
        case LOCOS_TRANSLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function patchLocoReducer(state = { loading: false, patchedLoco: [] }, action) {

    switch (action.type) {
        case PATCH_LOCOS_TRANSLATE_REQUEST:
            return { loading: true };
        case PATCH_LOCOS_TRANSLATE_SUCCESS:
            return { loading: false, patchedLoco: action.payload };
        case PATCH_LOCOS_TRANSLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}



export { listLocosReducer, getLocoTranslateReducer, patchLocoReducer }