import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuItems from './Navbar/MenuItems';
import { useSelector, useDispatch } from 'react-redux';
import { listPostType } from '../../actions/postTypeActions';
import Skeleton from 'react-loading-skeleton';
export default function SideNav (){

    const dispatch = useDispatch();

    const location = useSelector(state => state.location);

    const postTypeList = useSelector(state => state.postTypeList);
    const { postType, 
            loading,
            error } = postTypeList;

    useEffect(() => {
        dispatch(listPostType());
    }, []);


    return (
        <React.Fragment>
            
            {/* <!-- BEGIN: SideNav--> */}
            <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square  deep-purple lighten-5">
                <div className="brand-sidebar">
                    <h1 className="logo-wrapper">
                        <Link className="brand-logo darken-1" to="/">
                        <img className="hide-on-med-and-down" src="../../../app-assets/images/logo/logo.svg" alt="BWS digital logo" />
                        <img className="show-on-medium-and-down hide-on-med-and-up" src="../../../app-assets/images/logo/logo.svg" alt="BWS digital logo" />
                        {/* <span className="logo-text hide-on-med-and-down">Manager</span> */}
                        </Link>
                    {/* <a className="navbar-toggler" href="/"><i className="material-icons">radio_button_checked</i></a> */}
                    </h1>
                </div>

                {loading ? <div><Skeleton count={12} height={50} /></div> :
                    error ? <div>{error}</div> :
                        <MenuItems/>
                }

                <div className="navigation-background"></div>
            </aside>
            {/* <!-- END: SideNav--> */}
        </React.Fragment>
    );

}
