import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify'
import { pathListner } from '../../../actions/pathActions';
import UpdateInfosForm from './UpdateInfosForm'
import UpdatePasswordForm from './UpdatePasswordForm'
import FileManagerModal from '../../FilesManager/FileManagerModal'
export default function Loco() {


    const dispatch = useDispatch();
    useEffect(() => {
        window.initPlugins();
        dispatch(pathListner('/profile'));
    }, []);

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <FileManagerModal />
        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/*  Add new User popup  */}
                    <div className="contact-overlay"></div>
                   
                    <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                         <div className="container">
                            <div className="row">
                                <div className="col s10 m6 l6">
                                    <h5 className="breadcrumbs-title mt-0 mb-0"><span>Profile </span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                            <div className="card">
                                <div className="card-content">
                                    <p className="caption mb-0">Ici vous pouvez voir editer vous informations.</p>
                                </div>
                            </div>
                    </div>
                    <div className="container">

                        <div className="users-edit">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <ul className="tabs mb-2 row">
                                            <li className="tab">
                                                <a className="display-flex align-items-center active" id="account-tab" href="#account">
                                                    <i className="material-icons mr-1">person_outline</i><span>Information</span>
                                                </a>
                                            </li>
                                            <li className="tab">
                                                <a className="display-flex align-items-center" id="information-tab" href="#information">
                                                    <i className="material-icons mr-2">lock_outline</i><span>Modifier let mote de passe</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="divider mb-3"></div>
                                        <div className="row">
                                            <div className="col s12" id="account">
                                                
                                                {/* update infos  */}
                                                <UpdateInfosForm/>
                                                {/* update infos  */}
                                               
                                            </div>
                                            <div id="information">
                                                Mettez à jour votre mot de passe
                                                {/* update password  */}
                                                <UpdatePasswordForm/>
                                                {/* update password  */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-overlay"></div>
            </div>
         
        </div>

    </React.Fragment>;
}
