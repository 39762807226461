import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { pathListner } from '../../actions/pathActions';
import { listFiles } from '../../actions/fileManagerActions';
import FileManagerContent from './FileManagerContent';
import FileManagerFiltres from './FileManagerFiltres';
import FileManagerInfo from './FileManagerInfo';

import 'react-toastify/dist/ReactToastify.css';

export default function FilesManager(props) {

    const dispatch = useDispatch();
    
    useEffect(() => {

        dispatch(pathListner('/files-manager'));
        dispatch(listFiles());
        
    }, []);

    
    return <React.Fragment>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
                <div className="col s12">
                    <div className="container">
                        <div className="section app-file-manager-wrapper">

                            {/* <!-- File Manager app overlay --> */}
                            <div className="app-file-overlay"></div>
                            {/* <!-- /File Manager app overlay --> */}
                            
                            <FileManagerFiltres filtre={props.match.params.filtre} />
                            <FileManagerContent filtre={props.match.params.filtre} />
                            <FileManagerInfo filtre={props.match.params.filtre} />

                        </div>
                    </div>
                    <div className="content-overlay"></div>
                </div>
            </div>
        </React.Fragment>;
}

