import React from 'react';
import { Provider } from 'react-redux';
import {  BrowserRouter } from 'react-router-dom';


import ReactDOM from 'react-dom';
import store from './store';
import App from './App'
import { Router } from 'react-router';
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
          <BrowserRouter>
            <Router history={history}>
              <App/>
            </Router>
          </BrowserRouter>
      {/* </React.StrictMode> */}
    </Provider>
  ,document.getElementById('root')
);

