export const LOCOS_LIST_REQUEST = 'LOCOS_LIST_REQUEST';
export const LOCOS_LIST_SUCCESS = 'LOCOS_LIST_SUCCESS';
export const LOCOS_LIST_FAIL    = 'LOCOS_LIST_FAIL';

export const LOCOS_TRANSLATE_REQUEST    = 'LOCOS_TRANSLATE_REQUEST';
export const LOCOS_TRANSLATE_SUCCESS    = 'LOCOS_TRANSLATE_SUCCESS';
export const LOCOS_TRANSLATE_FAIL    = 'LOCOS_TRANSLATE_FAIL';

export const PATCH_LOCOS_TRANSLATE_REQUEST    = 'PATCH_LOCOS_TRANSLATE_REQUEST';
export const PATCH_LOCOS_TRANSLATE_SUCCESS    = 'PATCH_LOCOS_TRANSLATE_SUCCESS';
export const PATCH_LOCOS_TRANSLATE_FAIL    = 'PATCH_LOCOS_TRANSLATE_FAIL';