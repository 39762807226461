import React, { useEffect, useState } from 'react';
// packages 
import { ReactSortable } from "react-sortablejs";
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik ,FieldArray} from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

// fields 
import UrlField from './MenuItemFilds/UrlField'
import CategoryField from './MenuItemFilds/CategoryField'
import PostField from './MenuItemFilds/PostField'

// actions 
import { saveMenuItem, setMenuItems } from '../../actions/MenuItemsActions';


export default function MenuItemsZone() {
    let history = useHistory();

    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [idMenu, setIdMenu] = useState([]);
    
    const storeItems = useSelector(state => state.menuItems);
    const { menuItems } = storeItems;
    const { menuId } = useSelector(state => state.menuSelected);
    const { menuLangId } = useSelector(state => state.menuLangSelected);

    const cancelHandler =()=>{
        dispatch(setMenuItems([]));
        history.push("/menus");
    }
    
    useEffect(() => {
        setIdMenu(menuId);
        console.log(menuItems);
        setItems(menuItems);
        window.initPlugins();
    }, [storeItems])

    return (
        !items ? (
            <div className="card-panel gradient-45deg-blue-grey-blue-grey center">
                <span className="white-text"><i className="material-icons dp48">arrow_back</i> <br /> Vous pouvez ajouter des éléments dans le panneau latéral droit</span>
            </div>
        )  : 
           (
                <div className="card-panel">
                    <Formik
                        initialValues={{
                            id_menu: idMenu,
                            id_lang: menuLangId,
                            items: items
                        }}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            dispatch(saveMenuItem(values, (resp) => {
                                if (!resp.error) {
                                    toast.success('Menu elements Enregistrer');
                                }else{
                                    toast.error(resp.message);
                                }
                            }));
                        }}
                    >
                        {({ values, errors, touched, isSubmitting }) => {

                            return (
                                <Form className="formValidate">
                                    <div className="row">
                                        <FieldArray
                                            name="menu_items"

                                            render={arrayHelpers => (
                                                <ReactSortable
                                                    handle='.handle'
                                                    className="collapsible popout col s12 menu-sortable"
                                                    tag="ul"
                                                    list={values.items} setList={setItems}
                                                >

                                                    {
                                                        values.items.length ? (values.items.map((item, i) => {
                                                            return (
                                                            <li className="m-0 mb-3" key={"menu-item-" + i} >
                                                                {
                                                                    item.type === "url" ? 
                                                                    (
                                                                        <React.Fragment>
                                                                            <CollapsibleHeader icon="open_with" items={values.items} item={item} title="Lien personnalisé" />
                                                                            <UrlField index={i}  item={item} arrayHelpers={arrayHelpers} />
                                                                        </React.Fragment>
                                                                    ) 
                                                                    :
                                                                    item.type === "post" ? 
                                                                    (
                                                                        <React.Fragment>
                                                                            <CollapsibleHeader items={values.items} icon="open_with" item={item} title="Lien post" />
                                                                            <PostField index={i} item={item} arrayHelpers={arrayHelpers} />
                                                                        </React.Fragment>
                                                                    )
                                                                    :
                                                                    ( 
                                                                        <React.Fragment>
                                                                            <CollapsibleHeader items={values.items} icon="open_with" item={item} title="Lien categorie"/>
                                                                            <CategoryField index={i} item={item} arrayHelpers={arrayHelpers} />
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </li>);
                                                        })) : ''
                                                    }
                                                </ReactSortable>
                                            )}
                                        />



                                        {
                                            !items.length ? (
                                                <div className="card-panel deep-orange accent-1 center">
                                                    <span className="white-text"><i className="material-icons dp48">sentiment_neutral</i> <br/> Aucun Element trouvé <br/> vous pouvez ajouter des éléments dans le panneau latéral droit</span>
                                                </div>
                                            ) : ""
                                        }
                                        <div className="col s12">
                                            <button type="submit" className="btn indigo waves-effect waves-light mr-2" disabled={isSubmitting}>
                                                Save changes
                                                        </button>
                                            <button type="button" className="btn btn-light-pink waves-effect waves-light mb-1"
                                                onClick={cancelHandler}
                                            >Cancel</button>
                                        </div>
                                    </div>
                                </Form>
                            )

                        }}
                    </Formik>

                </div>
           )
        
    )
}


function CollapsibleHeader({ icon, title, item, items}) {
    const dispatch = useDispatch();

    const removeItem = (item)=>{
        let newItems = items.filter((el)=>{
            return el !== item
        })
        dispatch(setMenuItems(newItems));
    }
    return (
        <div className="collapsible-header" tabIndex="0">
            <span><i className="material-icons handle">{icon}</i>{title} </span>
            <a className="btn-floating delete-item  grey lighten-1" onClick={()=>{
                removeItem(item)
            }}>
                <span className="material-icons">delete_forever</span>
            </a>
        </div>
    )
}
