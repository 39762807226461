const { 
    CATEGORIES_POST_TYPES_LIST_REQUEST,
    CATEGORIES_POST_TYPES_LIST_SUCCESS,
    CATEGORIES_POST_TYPES_LIST_FAIL,
    CATEGORIES_LIST_REQUEST,
    CATEGORIES_LIST_SUCCESS,
    CATEGORIES_LIST_FAIL,
    CATEGORIES_SAVE_REQUEST,
    CATEGORIES_SAVE_SUCCESS,
    CATEGORIES_SAVE_FAIL,
    CATEGORIES_DELETE_REQUEST,
    CATEGORIES_DELETE_SUCCESS,
    CATEGORIES_DELETE_FAIL,
    CATEGORY_DETAILS_REQUEST,
    CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL,
    CATEGORIES_SIDEBAR_IS_TOGGLED,
    CATEGORY_LANG_REQUEST,
    CATEGORY_LANG_SUCCESS,
    CATEGORY_LANG_FAIL,
    CATEGORY_RESTORE_REQUEST,
    CATEGORY_RESTORE_SUCCESS,
    CATEGORY_RESTORE_FAIL,
    CATEGORY_DESTROY_REQUEST,
    CATEGORY_DESTROY_SUCCESS,
    CATEGORY_DESTROY_FAIL,
} = require("../constants/categoriesConstants");

function categoriesListReducer(state = { loading: true, categories: [] }, action) {

    switch (action.type) {
        case CATEGORIES_LIST_REQUEST:
            return { loading: true };
        case CATEGORIES_LIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case CATEGORIES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postTypesCategoriesReducer(state = { loading: true, postTypesCategories: [] }, action) {

    switch (action.type) {
        case CATEGORIES_POST_TYPES_LIST_REQUEST:
            return { loading: true };
        case CATEGORIES_POST_TYPES_LIST_SUCCESS:
            return { loading: false, postTypesCategories: action.payload };
        case CATEGORIES_POST_TYPES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function categorySaveReducer(state = { category: [] }, action) {

    switch (action.type) {
        case CATEGORIES_SAVE_REQUEST:
            return { loading: true };
        case CATEGORIES_SAVE_SUCCESS:
            return { loading: false, success: true, category: action.payload };
        case CATEGORIES_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function destroyCategoryReducer(state = { category: [] }, action) {

    switch (action.type) {
        case CATEGORY_DESTROY_REQUEST:
            return { loading: true };
        case CATEGORY_DESTROY_SUCCESS:
            return { loading: false, success: true, category: action.payload };
        case CATEGORY_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function categoryDeleteReducer(state = { category: [] }, action) {

    switch (action.type) {
        case CATEGORIES_DELETE_REQUEST:
            return { loading: true };
        case CATEGORIES_DELETE_SUCCESS:
            return { loading: false, success: true, category: action.payload };
        case CATEGORIES_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function categoryRestoreReducer(state = { category: [] }, action) {

    switch (action.type) {
        case CATEGORY_RESTORE_REQUEST:
            return { loading: true };
        case CATEGORY_RESTORE_SUCCESS:
            return { loading: false, success: true, category: action.payload };
        case CATEGORY_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedCategoryReducer(state = { category: null }, action) {

    switch (action.type) {
        case CATEGORY_DETAILS_REQUEST:
            return { loading: true };
        case CATEGORY_DETAILS_SUCCESS:
            return { loading: false, category: action.payload };
        case CATEGORY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function findCategoryByLangReducer(state = { category: null }, action) {

    switch (action.type) {
        case CATEGORY_LANG_REQUEST:
            return { loading: true };
        case CATEGORY_LANG_SUCCESS:
            return { loading: false, category: action.payload };
        case CATEGORY_LANG_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function toggleCategoriesSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case CATEGORIES_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { categoriesListReducer, categorySaveReducer, categoryDeleteReducer, selectedCategoryReducer, toggleCategoriesSidebarReducer, findCategoryByLangReducer, postTypesCategoriesReducer, categoryRestoreReducer, destroyCategoryReducer }