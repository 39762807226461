export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS'; 
export const ROLES_LIST_FAIL = 'ROLES_LIST_FAIL';
export const ROLES_SAVE_REQUEST = 'ROLES_SAVE_REQUEST';
export const ROLES_SAVE_SUCCESS = 'ROLES_SAVE_SUCCESS';
export const ROLES_SAVE_FAIL = 'ROLES_SAVE_FAIL'; 
export const ROLES_DELETE_REQUEST = 'ROLES_DELETE_REQUEST';
export const ROLES_DELETE_SUCCESS = 'ROLES_DELETE_SUCCESS';
export const ROLES_DELETE_FAIL = 'ROLES_DELETE_FAIL';
export const SELECTED_ROLE = 'SELECTED_ROLE';
export const ROLES_SIDEBAR_IS_TOGGLED = 'ROLES_SIDEBAR_IS_TOGGLED';