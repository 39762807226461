export const MENU_ITEMS_ADD_SUCCESS = 'MENU_ITEMS_ADD_SUCCESS';
export const MENU_ITEMS_ADD_REQUEST = 'MENU_ITEMS_ADD_REQUEST';

export const MENU_ITEMS_SAVE_REQUEST = 'MENU_ITEMS_SAVE_REQUEST';
export const MENU_ITEMS_SAVE_SUCCESS = 'MENU_ITEMS_SAVE_SUCCESS';
export const MENU_ITEMS_SAVE_FAIL = 'MENU_ITEMS_SAVE_FAIL';

export const MENU_ITEMS_LIST_REQUEST = 'MENU_ITEMS_LIST_REQUEST';
export const MENU_ITEMS_LIST_SUCCESS = 'MENU_ITEMS_LIST_SUCCESS';
export const MENU_ITEMS_LIST_FAIL = 'MENU_ITEMS_LIST_FAIL';

export const MENU_ID_SET_SUCCESS = 'MENU_ID_SET_SUCCESS';

export const MENU_Lang_ID_SET_SUCCESS = 'MENU_Lang_ID_SET_SUCCESS';
