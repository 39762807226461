import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listMenus, deleteMenu, selectedMenu, toggleMenusSidebar} from '../../actions/menusActions';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function MenusDatatable() {

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const menusList = useSelector(state => state.menus);
    const { menus, 
            loading,
            error } = menusList;
    const menuDelete = useSelector(state => state.menuDelete);
    const { loading: loadingDelete,
            success: successDelete,
        error: errorDelete } = menuDelete;


    const columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Titre',
            selector: 'menus_title',
            sortable: true,
        },
        {
           
            cell: (menu) => <Link to={"/menu-items/" + menu.id} data-for="menu_link" data-iscapture="true" data-tip="Add items to this menu">
                    <i className="material-icons">add_box</i>
                    <ReactTooltip place="bottom" id="menu_link" type="dark" effect="solid" />
                </Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( menu ) => <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { editMenuHandler(menu); } }>
                <i className="material-icons">edit</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( menu ) => <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deleteMenuHandler(menu); } }>
                <i className="material-icons">delete</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(menus);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listMenus());
        }

    }, [ deleteSuccess, menus]);

    const editMenuHandler = ( menuData ) => {
        dispatch(selectedMenu(menuData));
        dispatch(toggleMenusSidebar(true));
    }

    const deleteMenuHandler = ( menuData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteMenu(menuData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Menu bien supprimer.',
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = menus.filter(item => item.menus_title && item.menus_title.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div>loading ...</div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   

            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

