import React, { useEffect, useState } from 'react'

// packages 
import Skeleton from 'react-loading-skeleton'
import { ToastContainer } from 'react-toastify'
import { Link } from 'react-router-dom'
import {  useDispatch } from 'react-redux'

// actions 
import { listLanguages, currentLanguage} from '../../actions/languagesActions'
import { pathListner } from '../../actions/pathActions'
import { listCategories } from '../../actions/categoriesActions';


// actions 
import { listCategoryItems, setCategoryItems, setCategoryId } from '../../actions/categoryItemsActions';
// components 

import  CategoryItemsSidebar  from "./CategoryItemsSidebar"
import  CategoryItemsZone  from "./CategoryItemsZone"

export default function CategoryItems(props){

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [hasCategories, setHasCategories] = useState(false);

    useEffect(() => {
        dispatch(listLanguages());
        dispatch(currentLanguage());
        dispatch(listCategories((categories) => {
            if (categories.length) {
                setHasCategories(true)
                if (props.match.params.id) {
                    dispatch(setCategoryId(props.match.params.id));
                    dispatch(listCategoryItems(props.match.params.id, (items) => {
                        dispatch(setCategoryItems(items))
                        setLoading(false)
                    }));

                } else {
                    dispatch(setCategoryId(categories[0].id_cat));
                    dispatch(listCategoryItems(categories[0].id_cat, (items) => {
                        dispatch(setCategoryItems(items))
                        setLoading(false)
                    }));

                }
            } else {
                setLoading(false)
            }
        }));
        dispatch(pathListner('/category-items'));

        window.initPlugins();
    }, []);
    
     return (
  
        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    <section className="tabs-vertical mt-1 section">

                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />


                         {
                             loading ? (
                                 <div className="row container">
                                     <div className="l12 pt-5 pb-5  ml-3 mr-3"> <Skeleton count={5} height={50} /></div>
                                 </div>
                             ) : (
                                     hasCategories ? (
                                         <div className="row container">
                                             <div className="col l4 s12">
                                                 <CategoryItemsSidebar />
                                             </div>
                                             <div className="col l8 s12">
                                                 <CategoryItemsZone />
                                             </div>
                                         </div>
                                     ) : (
                                             <div className="row container">
                                                 <div className="card-panel grey darken-4 accent-1 center  l12 pt-5 pb-5 mb-3 mt-3  ml-3 mr-3">
                                                     <span >
                                                         <h4 className="white-text">Aucun Categorie trouvé</h4>
                                                         <Link to="/categories" className="btn-small mb-3 mt-3 gradient-45deg-indigo-light-blue">
                                                             <i className="material-icons left">add_circle</i>Ajouter Categorie</Link></span>
                                                 </div>
                                             </div>

                                         )

                                 )
                         }
                           
                           
                           
                    </section>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

    )
}

