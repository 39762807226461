const { FILES_LIST_REQUEST, 
        FILES_LIST_SUCCESS, 
        FILES_LIST_FAIL,
        SELECTED_FILE,
        SELECTED_MULTI_FILES,
        FILEMANAGER_MODAL_IS_TOGGLED,
        UPLOAD_FILES_REQUEST,
        UPLOAD_FILES_SUCCESS,
        UPLOAD_FILES_FAIL,
        UPLOAD_FILES_IN_PROGRESS
    } = require("../constants/filesManagerConstants");

function filesListReducer(state = {loading:true, files:[]}, action){

    switch(action.type){
        case FILES_LIST_REQUEST:
            return {loading : true};
        case FILES_LIST_SUCCESS:
            return {loading: false, files: action.payload};
        case FILES_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function selectedFileReducer(state = { file: null }, action) {

    switch (action.type) {
        case SELECTED_FILE:
            return { file: action.payload };
        default:
            return state;
    }

}

function selectedMultiFilesReducer(state = { files: [] }, action) {

    switch (action.type) {
        case SELECTED_MULTI_FILES:
            return { files: action.payload };
        default:
            return state;
    }

}


function toggleFilesManagerModalReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case FILEMANAGER_MODAL_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}

function uploadFilesReducer(state = { loading: true, uploadData: [] }, action) {

    switch (action.type) {
        case UPLOAD_FILES_REQUEST:
            return { loading: true };
        case UPLOAD_FILES_IN_PROGRESS:
            return { loading: true, uploadData: action.payload };
        case UPLOAD_FILES_SUCCESS:
            return { loading: false, uploadData: action.payload };
        case UPLOAD_FILES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

export { filesListReducer, selectedFileReducer, selectedMultiFilesReducer, toggleFilesManagerModalReducer, uploadFilesReducer}