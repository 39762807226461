import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listPostTemplates, deletePostTemplates, selectedPostTemplate, restorePostTemplates, destroyPostTempate} from '../../actions/postTemplatesActions';
import Swal from 'sweetalert2';

export default function PostTemplatesDatatable() {

    const dispatch = useDispatch();

    const history = useHistory();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const postTemplatesList = useSelector(state => state.postTemplatesList);
    const { postTemplates, 
            loading,
            error } = postTemplatesList;

    const postTemplatesDelete = useSelector(state => state.postTemplatesDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = postTemplatesDelete;

    const postTemplatesSave = useSelector(state => state.postTemplatesSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = postTemplatesSave;


    const columns = [
        {
            name: 'PostTemplates',
            selector: 'postTemplates_name',
            sortable: true,
        },
        {
            cell: (postTemplates) => !postTemplates.deleted_at ? <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { editPostTemplatesHandler(postTemplates); } }>
                <i className="material-icons">edit</i>
            </button> : <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyPostTemplatesHandler(postTemplates); }}>
                    <i className="material-icons" style={{ color: "#d32f2f" }}>delete_forever</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( postTemplates ) => 
                !postTemplates.deleted_at ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deletePostTemplatesHandler(postTemplates); }}>
                    <i className="material-icons">delete</i>
                </button> : <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restorePostTemplatesHandler(postTemplates); }}>
                        <i className="material-icons">restore</i>
                    </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(postTemplates);
        dispatch(selectedPostTemplate(null));

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listPostTemplates());
        }

    }, [successSave, deleteSuccess, postTemplates]);

    const editPostTemplatesHandler = ( postTemplatesData ) => {
        dispatch(selectedPostTemplate(postTemplatesData));
        history.push('/post-templates-form');
    }

    const destroyPostTemplatesHandler = (template) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'supprimer définitivement'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyPostTempate(template, (response) => {
                    history.push('/post-templates')
                    dispatch(listPostTemplates());
                    Swal.fire(
                        'supprimer définitivement',
                        'Modèles bien supprimer.',
                        'success'
                    )
                }));

            }
        })

    }
    const restorePostTemplatesHandler = (template) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Restaurer'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restorePostTemplates(template, (response) => {
                    dispatch(listPostTemplates());
                    history.push('/post-templates')
                    Swal.fire(
                        'Restaurer',
                        'Le template bien Restaurer.',
                        'success'
                    )
                }));

            }
        })

    }

    const deletePostTemplatesHandler = ( postTemplatesData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deletePostTemplates(postTemplatesData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Utilisateur bien supprimer.',
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = postTemplates.filter(item => item.postTemplates_name && item.postTemplates_name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div>loading ...</div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

