const { POSTTYPE_LIST_REQUEST, 
        POSTTYPE_LIST_SUCCESS, 
        POSTTYPE_LIST_FAIL,
        POSTTYPE_SAVE_REQUEST,
        POSTTYPE_SAVE_SUCCESS,
        POSTTYPE_SAVE_FAIL, 
        POSTTYPE_DELETE_REQUEST,
        POSTTYPE_DELETE_SUCCESS,
        POSTTYPE_DELETE_FAIL,
        SELECTED_POSTTYPE,
        POSTTYPE_SIDEBAR_IS_TOGGLED,
        POSTTYPE_RESTORE_REQUEST,
        POSTTYPE_RESTORE_SUCCESS,
        POSTTYPE_RESTORE_FAIL,
        POSTTYPE_DESTROY_REQUEST,
        POSTTYPE_DESTROY_SUCCESS,
        POSTTYPE_DESTROY_FAIL,
        POSTTYPE_TRASHED_LIST_REQUEST,
        POSTTYPE_TRASHED_LIST_SUCCESS,
        POSTTYPE_TRASHED_LIST_FAIL
    } = require("../constants/postTypeConstants");


function listTrashedPostTypeReducer(state = { loading: true, postType: [] }, action) {

    switch (action.type) {
        case POSTTYPE_TRASHED_LIST_REQUEST:
            return { loading: true };
        case POSTTYPE_TRASHED_LIST_SUCCESS:
            return { loading: false, postType: action.payload };
        case POSTTYPE_TRASHED_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function postTypeListReducer(state = {loading:true, postType:[]}, action){

    switch(action.type){
        case POSTTYPE_LIST_REQUEST:
            return {loading : true};
        case POSTTYPE_LIST_SUCCESS:
            return {loading: false, postType: action.payload};
        case POSTTYPE_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function postTypeRestoreReducer(state = { postType: [] }, action) {

    switch (action.type) {
        case POSTTYPE_RESTORE_REQUEST:
            return { loading: true };
        case POSTTYPE_RESTORE_SUCCESS:
            return { loading: false, success: true, postType: action.payload };
        case POSTTYPE_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function destroyPostTypeReducer(state = { postType: [] }, action) {

    switch (action.type) {
        case POSTTYPE_DESTROY_REQUEST:
            return { loading: true };
        case POSTTYPE_DESTROY_SUCCESS:
            return { loading: false, success: true, postType: action.payload };
        case POSTTYPE_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function postTypeSaveReducer(state = {postType:[]}, action){

    switch(action.type){
        case POSTTYPE_SAVE_REQUEST:
            return {loading : true};
        case POSTTYPE_SAVE_SUCCESS:
            return {loading: false, success: true, postType: action.payload};
        case POSTTYPE_SAVE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function postTypeDeleteReducer(state = {postType:[]}, action){

    switch(action.type){
        case POSTTYPE_DELETE_REQUEST:
            return {loading : true};
        case POSTTYPE_DELETE_SUCCESS:
            return {loading: false, success: true, postType: action.payload};
        case POSTTYPE_DELETE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function selectedPostTypeReducer(state = { postType: null }, action) {

    switch (action.type) {
        case SELECTED_POSTTYPE:
            return { postType: action.payload };
        default:
            return state;
    }

}

function togglePostTypeSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case POSTTYPE_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { postTypeListReducer, postTypeSaveReducer, postTypeDeleteReducer, selectedPostTypeReducer, togglePostTypeSidebarReducer, postTypeRestoreReducer, destroyPostTypeReducer, listTrashedPostTypeReducer}