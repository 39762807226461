import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    CATEGORY_ITEMS_ADD_SUCCESS,
    CATEGORY_ITEMS_SAVE_REQUEST,
    CATEGORY_ITEMS_SAVE_SUCCESS,
    CATEGORY_ITEMS_SAVE_FAIL,
    CATEGORY_ITEMS_LIST_REQUEST,
    CATEGORY_ITEMS_LIST_SUCCESS,
    CATEGORY_ITEMS_LIST_FAIL,
    CATEGORY_ITEM_REQUEST,
    CATEGORY_ITEM_SUCCESS,
    CATEGORY_ITEM_FAIL,
    CATEGORY_DELETE_ITEM_REQUEST,
    CATEGORY_DELETE_ITEM_SUCCESS,
    CATEGORY_DELETE_ITEM_FAIL,
    CATEGORY_ID_SET_SUCCESS,
    CATEGORY_LANG_ID_SET_SUCCESS
} = require("../constants/categoryItemsConstants");

const listCategoryItems = (id_cat, cb) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_ITEMS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            headers: headers,
            url: apiProxy+'/api/category-items/' + id_cat ,
        });
        typeof cb == "function" && cb(data);
        dispatch({ type: CATEGORY_ITEMS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_ITEMS_LIST_FAIL, payload: error.message });
    }

}

const findCategoryItem = (id_lang, id_cat_item, cb) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_ITEM_REQUEST });
        const { data } = await axios({
            method: 'get',
            headers: headers,
            url: apiProxy+'/api/category-items/item/' + id_lang + '/' + id_cat_item,
        });
        typeof cb == "function" && cb(data);
        dispatch({ type: CATEGORY_ITEM_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_ITEM_FAIL, payload: error.message });
    }

}


const setCategoryItems = (items) => async (dispatch) => {
    dispatch({ type: CATEGORY_ITEMS_ADD_SUCCESS, payload: items });
}

const setCategoryId = (id) => async (dispatch) => {
    dispatch({ type: CATEGORY_ID_SET_SUCCESS, payload: id });
}

const setCategoryLangId = (id) => async (dispatch) => {
    dispatch({ type: CATEGORY_LANG_ID_SET_SUCCESS, payload: id });
}


const saveCategoryItem = (dadaForm, callback) => async (dispatch) => {


    dispatch({ type: CATEGORY_ITEMS_SAVE_REQUEST, payload: { dadaForm } });
    axios({
        method: 'post',
        headers: headers,
        url: apiProxy+"/api/category-items",
        data: dadaForm
    }).then(data => {
        callback({
            error: false,
            data: data,
            message: null
        });
        dispatch({ type: CATEGORY_ITEMS_SAVE_SUCCESS, payload: data });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data.message
        });
        dispatch({ type: CATEGORY_ITEMS_SAVE_FAIL, payload: err.response.data.message });
    });;



}


const deleteCategoryItem = (id_cat_item, cb) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_DELETE_ITEM_REQUEST });
        const { data } = await axios({
            method: 'delete',
            headers: headers,
            url: apiProxy+'/api/category-items/' + id_cat_item,
        });
        typeof cb == "function" && cb(data);
        dispatch({ type: CATEGORY_DELETE_ITEM_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_DELETE_ITEM_FAIL, payload: error.message });
    }

}

export { setCategoryItems, saveCategoryItem, listCategoryItems, setCategoryId, setCategoryLangId, findCategoryItem, deleteCategoryItem}