import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    LOCOS_LIST_REQUEST,
    LOCOS_LIST_SUCCESS,
    LOCOS_LIST_FAIL,
    LOCOS_TRANSLATE_REQUEST,
    LOCOS_TRANSLATE_SUCCESS,
    LOCOS_TRANSLATE_FAIL,
    PATCH_LOCOS_TRANSLATE_REQUEST,
    PATCH_LOCOS_TRANSLATE_SUCCESS,
    PATCH_LOCOS_TRANSLATE_FAIL,
} = require("../constants/locosConstants");

const locosList = (cb) => async (dispatch) => {
    try {
        dispatch({ type: LOCOS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy + '/api/locos',
            headers
        });
        console.log(data);
        typeof cb == "function" && cb(data)
        dispatch({ type: LOCOS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOCOS_LIST_FAIL, payload: error.message });
    }

}


const getLocoTranslate = (loco,cb) => async (dispatch) => {
    try {
        dispatch({ type: LOCOS_TRANSLATE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy + "/api/locos/translate",
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
        dispatch({ type: LOCOS_TRANSLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOCOS_TRANSLATE_FAIL, payload: error.message });
    }

}

const patchLocoTranslate = (loco,cb) => async (dispatch) => {
    try {
        dispatch({ type: PATCH_LOCOS_TRANSLATE_REQUEST });
        const { data } = await axios({
            method: 'patch',
            url: apiProxy + "/api/locos/" + loco.id,
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
        dispatch({ type: PATCH_LOCOS_TRANSLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PATCH_LOCOS_TRANSLATE_FAIL, payload: error.message });
    }

}

const storeNewLoco = (loco,cb) => async (dispatch) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: apiProxy + "/api/locos/",
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
    } catch (error) {
        typeof cb == "function" && cb(error)
    }

}

export { locosList, getLocoTranslate, patchLocoTranslate, storeNewLoco }