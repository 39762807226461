export const CATEGORY_ITEMS_ADD_SUCCESS = 'CATEGORY_ITEMS_ADD_SUCCESS';
export const CATEGORY_ITEMS_ADD_REQUEST = 'CATEGORY_ITEMS_ADD_REQUEST';

export const CATEGORY_ITEMS_SAVE_REQUEST = 'CATEGORY_ITEMS_SAVE_REQUEST';
export const CATEGORY_ITEMS_SAVE_SUCCESS = 'CATEGORY_ITEMS_SAVE_SUCCESS';
export const CATEGORY_ITEMS_SAVE_FAIL = 'CATEGORY_ITEMS_SAVE_FAIL';

export const CATEGORY_ITEMS_LIST_REQUEST = 'CATEGORY_ITEMS_LIST_REQUEST';
export const CATEGORY_ITEMS_LIST_SUCCESS = 'CATEGORY_ITEMS_LIST_SUCCESS';
export const CATEGORY_ITEMS_LIST_FAIL = 'CATEGORY_ITEMS_LIST_FAIL';

export const CATEGORY_ID_SET_SUCCESS = 'CATEGORY_ID_SET_SUCCESS';

export const CATEGORY_LANG_ID_SET_SUCCESS = 'CATEGORY_LANG_ID_SET_SUCCESS';


export const CATEGORY_ITEM_REQUEST  = 'CATEGORY_ITEM_REQUEST';
export const CATEGORY_ITEM_SUCCESS  = 'CATEGORY_ITEM_SUCCESS';
export const CATEGORY_ITEM_FAIL     = 'CATEGORY_ITEM_FAIL';

export const CATEGORY_DELETE_ITEM_REQUEST     = 'CATEGORY_DELETE_ITEM_REQUEST';
export const CATEGORY_DELETE_ITEM_SUCCESS     = 'CATEGORY_DELETE_ITEM_SUCCESS';
export const CATEGORY_DELETE_ITEM_FAIL        = 'CATEGORY_DELETE_ITEM_FAIL';