import React from 'react'
import { Switch, Route, BrowserRouter} from 'react-router-dom';
import Login from '../components/auth/Login'
import ForgotPassword from '../components/auth/ForgotPassword'
import ResetPassword from '../components/auth/ResetPassword'
import NotFound from '../components/errors/notFound'

export default function AuthRoutes() {
  
    return (
        <div id="main">
                <Switch>
                    <Route path="/" exact={true} component={Login} />
                    <Route path="/login" exact={true} component={Login} />
                    <Route path="/forgot-password" exact={true} component={ForgotPassword} />
                    <Route path="/verify-forgot-password-link/:iv/:content" exact={true} component={ResetPassword} />
                    <Route component={NotFound} />
                </Switch>
        </div>
    )
}
