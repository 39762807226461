import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { 
    POSTTYPE_LIST_REQUEST, 
    POSTTYPE_LIST_SUCCESS, 
    POSTTYPE_LIST_FAIL,
    POSTTYPE_SAVE_REQUEST,
    POSTTYPE_SAVE_SUCCESS,
    POSTTYPE_SAVE_FAIL, 
    POSTTYPE_DELETE_REQUEST,
    POSTTYPE_DELETE_SUCCESS,
    POSTTYPE_DELETE_FAIL,
    SELECTED_POSTTYPE,
    POSTTYPE_SIDEBAR_IS_TOGGLED,
    POSTTYPE_RESTORE_REQUEST,
    POSTTYPE_RESTORE_SUCCESS,
    POSTTYPE_RESTORE_FAIL,
    POSTTYPE_DESTROY_REQUEST,
    POSTTYPE_DESTROY_SUCCESS,
    POSTTYPE_DESTROY_FAIL,
    POSTTYPE_TRASHED_LIST_REQUEST,
    POSTTYPE_TRASHED_LIST_SUCCESS,
    POSTTYPE_TRASHED_LIST_FAIL,
} = require("../constants/postTypeConstants");

const listPostType = (cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTTYPE_LIST_REQUEST });
        const { data } = await axios({ url: apiProxy+"/api/post_type", type: "get", headers});
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTTYPE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTYPE_LIST_FAIL, payload: error.message });
    }
    
}

const listTrashedPostType = (cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTTYPE_TRASHED_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/post_type/trashed",
            headers
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTTYPE_TRASHED_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTYPE_TRASHED_LIST_FAIL, payload: error.message });
    }
    
}


const restorePostType = (postType, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTTYPE_RESTORE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy+"/api/post_type/restore/" + postType.id,
            headers
        });
        callback(data);
        dispatch({ type: POSTTYPE_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTYPE_RESTORE_FAIL, payload: error.message });
    }

}

const destroyPostType = (postType, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTTYPE_DESTROY_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/post_type/destroy/" + postType.id,
            headers
        });
        callback(data);
        dispatch({ type: POSTTYPE_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTYPE_DESTROY_FAIL, payload: error.message });
    }

}

const savePostType = (postType, callback) => async (dispatch) => {

    try {

        if(!postType.id){
            dispatch({ type: POSTTYPE_SAVE_REQUEST, payload: {postType} });
            const { data } = await axios({
                data: postType,
                method: 'post',
                url: apiProxy+"/api/post_type",
                headers
            });
            callback(data);
            dispatch({ type: POSTTYPE_SAVE_SUCCESS, payload: data });
        }else{
            dispatch({ type: POSTTYPE_SAVE_REQUEST, payload: {postType} });
            const { data } = await axios({
                data: postType,
                method: 'patch',
                url: apiProxy+"/api/post_type/" + postType.id,
                headers
            });
            callback(data);
            dispatch({ type: POSTTYPE_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: POSTTYPE_SAVE_FAIL, payload: error.message });
    }
    
}

const deletePostType = (postsType, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTTYPE_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/post_type/" + postsType.id,
            headers
        });
        callback(data);
        dispatch({ type: POSTTYPE_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTTYPE_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedPostType = (postsType) => async (dispatch) => {
    dispatch({ type: SELECTED_POSTTYPE, payload: postsType });
}

const togglePostTypeSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: POSTTYPE_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listPostType, savePostType, deletePostType, selectedPostType, togglePostTypeSidebar, listTrashedPostType, restorePostType, destroyPostType}