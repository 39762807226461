import React, { useEffect} from 'react';
import {  useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { pathListner } from '../../actions/pathActions';

import { listLanguages, selectedLanguage, toggleLanguagesSidebar, listTrashedLanguages} from '../../actions/languagesActions';
import LanguagesFormSidebar from './LanguagesFormSidebar';
import LanguagesFiltre from './LanguagesFiltre';
import LanguagesDatatable from './LanguagesDatatable';
import FileManagerModal from '../FilesManager/FileManagerModal';

export default function Languages(props) {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pathListner('/languages'));

        if (typeof props.match.params.trush != "undefined") {
            dispatch(listTrashedLanguages());
        }else{
            dispatch(listLanguages());
        }
        
    }, []);

    const addLanguage = () => {
        dispatch(selectedLanguage(null));
        dispatch(toggleLanguagesSidebar(true));
    }
    
    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <FileManagerModal />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                    <div style={{bottom: '54px' , right: '19px'}} className="fixed-action-btn direction-top">
                        <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addLanguage}>
                            <i className="material-icons">add_circle</i>
                        </a>
                    </div>
                    {/* <!-- Add new User popup Ends--> */}

                    <LanguagesFiltre props={props}/>

                    <LanguagesDatatable />

                    <LanguagesFormSidebar/>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
        
        </React.Fragment>;
}

