import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    AUTHORIZED_APPS_LIST_REQUEST,
    AUTHORIZED_APPS_LIST_SUCCESS,
    AUTHORIZED_APPS_LIST_FAIL,
    CURRENT_AUTHORIZED_APP_REQUEST,
    CURRENT_AUTHORIZED_APP_SUCCESS,
    CURRENT_AUTHORIZED_APP_FAIL,
    AUTHORIZED_APPS_SAVE_REQUEST,
    AUTHORIZED_APPS_SAVE_SUCCESS,
    AUTHORIZED_APPS_SAVE_FAIL,
    AUTHORIZED_APPS_DELETE_REQUEST,
    AUTHORIZED_APPS_DELETE_SUCCESS,
    AUTHORIZED_APPS_DELETE_FAIL,
    SELECTED_AUTHORIZED_APP,
    AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED,
    AUTHORIZED_APPS_RESTORE_REQUEST,
    AUTHORIZED_APPS_RESTORE_SUCCESS,
    AUTHORIZED_APPS_RESTORE_FAIL,
    AUTHORIZED_APPS_DESTROY_REQUEST,
    AUTHORIZED_APPS_DESTROY_SUCCESS,
    AUTHORIZED_APPS_DESTROY_FAIL
} = require("../constants/authorizedAppsConstants");

const listAuthorizedApps = (cb) => async (dispatch) => {
    try {
        dispatch({ type: AUTHORIZED_APPS_LIST_REQUEST });
        const { data } = await axios({
            headers: headers,
            method: 'get',
            url: apiProxy+'/api/authorized-apps',
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: AUTHORIZED_APPS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: AUTHORIZED_APPS_LIST_FAIL, payload: error.message });
    }

}

const listTrashedAuthorizedApps = (cb) => async (dispatch) => {
    try {
        dispatch({ type: AUTHORIZED_APPS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            headers: headers,
            url: apiProxy+'/api/authorized-apps/trashed',
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: AUTHORIZED_APPS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: AUTHORIZED_APPS_LIST_FAIL, payload: error.message });
    }

}

const currentAuthorizedApp = (cb) => async (dispatch) => {
    try {
        dispatch({ type: CURRENT_AUTHORIZED_APP_REQUEST });
        const { data } = await axios({
            method: 'get',
            headers: headers,
            url: apiProxy+'/api/authorized-apps/current/lang',
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: CURRENT_AUTHORIZED_APP_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CURRENT_AUTHORIZED_APP_FAIL, payload: error.message });
    }

}

const saveAuthorizedApp = (app, callback) => async (dispatch) => {


    if (!app.id) {
        dispatch({ type: AUTHORIZED_APPS_SAVE_REQUEST, payload: { app } });
        axios(
            {
                method: 'post',
                headers: headers,
                url: apiProxy+'/api/authorized-apps',
                data: app
            }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: AUTHORIZED_APPS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: AUTHORIZED_APPS_SAVE_FAIL, payload: err.response.data.message });
        });

    } else {
        dispatch({ type: AUTHORIZED_APPS_SAVE_REQUEST, payload: { app } });
        axios({
                method: 'patch',
                headers: headers,
                url: apiProxy+"/api/authorized-apps/" + app.id,
                data: app
            }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: AUTHORIZED_APPS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: AUTHORIZED_APPS_SAVE_FAIL, payload: err.response.data.message });
        });;

    }



}

const deleteAuthorizedApp = (app, callback) => async (dispatch) => {
    try {
        dispatch({ type: AUTHORIZED_APPS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            headers: headers,
            url: apiProxy+"/api/authorized-apps/" + app.id,
        });
        callback(data);
        dispatch({ type: AUTHORIZED_APPS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: AUTHORIZED_APPS_DELETE_FAIL, payload: error.message });
    }

}

const restoreAuthorizedApp = (app, callback) => async (dispatch) => {
    try {
        dispatch({ type: AUTHORIZED_APPS_RESTORE_REQUEST });
        const { data } = await axios({
                method: 'post',
                headers: headers,
                url: apiProxy+"/api/authorized-apps/restore/" + app.id,
            });
        callback(data);
        dispatch({ type: AUTHORIZED_APPS_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: AUTHORIZED_APPS_RESTORE_FAIL, payload: error.message });
    }

}

const destroyAuthorizedApp = (app, callback) => async (dispatch) => {
    try {
        dispatch({ type: AUTHORIZED_APPS_DESTROY_REQUEST });
        const { data } = await axios.delete({
                method: 'post',
                headers: headers,
                url: apiProxy+"/api/authorized-apps/destroy/" + app.id,
            });
        callback(data);
        dispatch({ type: AUTHORIZED_APPS_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: AUTHORIZED_APPS_DESTROY_FAIL, payload: error.message });
    }

}

const selectedAuthorizedApp = (app) => async (dispatch) => {
    dispatch({ type: SELECTED_AUTHORIZED_APP, payload: app });
}

const toggleAuthorizedAppsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listAuthorizedApps, saveAuthorizedApp, deleteAuthorizedApp, selectedAuthorizedApp, toggleAuthorizedAppsSidebar, currentAuthorizedApp, listTrashedAuthorizedApps, restoreAuthorizedApp, destroyAuthorizedApp }