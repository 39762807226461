import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { 
    PERMISSIONS_LIST_REQUEST,
    PERMISSIONS_LIST_SUCCESS,
    PERMISSIONS_LIST_FAIL,
    PERMISSIONS_SAVE_REQUEST,
    PERMISSIONS_SAVE_SUCCESS,
    PERMISSIONS_SAVE_FAIL,
    PERMISSIONS_DELETE_REQUEST,
    PERMISSIONS_DELETE_SUCCESS,
    PERMISSIONS_DELETE_FAIL,
    SELECTED_PERMISSION,
    PERMISSIONS_SIDEBAR_IS_TOGGLED

} = require("../constants/permissionsConstants");

const listPermissions = () => async (dispatch) => {
    try {
        dispatch({ type: PERMISSIONS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/permissions",
            headers,
        });
        dispatch({ type: PERMISSIONS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PERMISSIONS_LIST_FAIL, payload: error.message });
    }

}

const savePermission = (permission, callback) => async (dispatch) => {

    try {
        console.log(permission);
        if (!permission.id) {
            dispatch({ type: PERMISSIONS_SAVE_REQUEST, payload: { permission } });
            const { data } = await axios({
                method: 'post',
                url: apiProxy+"/api/permissions",
                headers,
                data: permission
            });
            callback(data);
            dispatch({ type: PERMISSIONS_SAVE_SUCCESS, payload: data });
        } else {
            dispatch({ type: PERMISSIONS_SAVE_REQUEST, payload: { permission } });
            const { data } = await axios({
                method: 'patch',
                url: apiProxy+"/api/permissions/" + permission.id,
                headers,
                data: permission
            });
            callback(data);
            dispatch({ type: PERMISSIONS_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: PERMISSIONS_SAVE_FAIL, payload: error.message });
    }

}

const deletePermission = (permission, callback) => async (dispatch) => {
    try {
        dispatch({ type: PERMISSIONS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/permissions/" + permission.id,
            headers,
        });
        callback(data);
        dispatch({ type: PERMISSIONS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PERMISSIONS_DELETE_FAIL, payload: error.message });
    }

}

const selectedPermissions = (permission) => async (dispatch) => {
    dispatch({ type: SELECTED_PERMISSION, payload: permission });
}

const togglePermissionsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: PERMISSIONS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listPermissions, selectedPermissions, togglePermissionsSidebar, deletePermission, savePermission}