import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { 
    LANGUAGES_LIST_REQUEST,
    LANGUAGES_LIST_SUCCESS,
    LANGUAGES_LIST_FAIL,
    CURRENT_LANGUAGE_REQUEST,
    CURRENT_LANGUAGE_SUCCESS,
    CURRENT_LANGUAGE_FAIL,
    LANGUAGES_SAVE_REQUEST,
    LANGUAGES_SAVE_SUCCESS,
    LANGUAGES_SAVE_FAIL,
    LANGUAGES_DELETE_REQUEST,
    LANGUAGES_DELETE_SUCCESS,
    LANGUAGES_DELETE_FAIL,
    SELECTED_LANGUAGE,
    LANGUAGES_SIDEBAR_IS_TOGGLED,
    LANGUAGES_RESTORE_REQUEST,
    LANGUAGES_RESTORE_SUCCESS,
    LANGUAGES_RESTORE_FAIL,
    LANGUAGES_DESTROY_REQUEST,
    LANGUAGES_DESTROY_SUCCESS,
    LANGUAGES_DESTROY_FAIL 
} = require("../constants/languagesConstants");

const listLanguages = (cb) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/langs',
            headers
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: LANGUAGES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LANGUAGES_LIST_FAIL, payload: error.message });
    }

}

const listTrashedLanguages = (cb) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/langs/trashed',
            headers
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: LANGUAGES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LANGUAGES_LIST_FAIL, payload: error.message });
    }

}

const currentLanguage = (cb) => async (dispatch) => {
    try {
        dispatch({ type: CURRENT_LANGUAGE_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/langs/current/lang',
            headers
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: CURRENT_LANGUAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CURRENT_LANGUAGE_FAIL, payload: error.message });
    }

}

const saveLanguage = (language, callback) => async (dispatch) => {


    if (!language.id) {
        dispatch({ type: LANGUAGES_SAVE_REQUEST, payload: { language } });
        axios({
            method: 'post',
            url: apiProxy+"/api/langs",
            data: language,
            headers
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: LANGUAGES_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: LANGUAGES_SAVE_FAIL, payload: err.response.data.message });
        });

    } else {
        dispatch({ type: LANGUAGES_SAVE_REQUEST, payload: { language } });
        axios({
            method: 'patch',
            url: apiProxy+"/api/langs/" + language.id,
            data: language,
            headers
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: LANGUAGES_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: LANGUAGES_SAVE_FAIL, payload: err.response.data.message });
        });;

    }



}

const deleteLanguage = (language, callback) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/langs/" + language.id,
            headers
        });
        callback(data);
        dispatch({ type: LANGUAGES_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LANGUAGES_DELETE_FAIL, payload: error.message });
    }

}

const restoreLanguage = (language, callback) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_RESTORE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy+"/api/langs/restore/" + language.id,
            headers
        });
        callback(data);
        dispatch({ type: LANGUAGES_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LANGUAGES_RESTORE_FAIL, payload: error.message });
    }

}

const destroyLanguage = (language, callback) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_DESTROY_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/langs/destroy/" + language.id,
            headers
        });
        callback(data);
        dispatch({ type: LANGUAGES_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LANGUAGES_DESTROY_FAIL, payload: error.message });
    }

}

const selectedLanguage = (language) => async (dispatch) => {
    dispatch({ type: SELECTED_LANGUAGE, payload: language });
}

const toggleLanguagesSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: LANGUAGES_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listLanguages, saveLanguage, deleteLanguage, selectedLanguage, toggleLanguagesSidebar, currentLanguage, listTrashedLanguages, restoreLanguage, destroyLanguage}