import React from 'react'

// packages 
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom'

// actions 
import { listCategoryItems, setCategoryItems, setCategoryId } from '../../actions/categoryItemsActions';
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect'

export default function CategoryItemsSidebar() {
    
    const dispatch = useDispatch();
    const { categories,loading,error } = useSelector(state => state.categories);
    const { categoryId } = useSelector(state => state.categorySelected);
    const categoryOptions = categories.map(cat=>{
        return {
            label: cat.categories_title,
            value: cat.id_cat
        }
    })
    
   
    const handelCategoryChange = (val) => {
        dispatch(setCategoryId(val))
        dispatch(listCategoryItems(val, (items) => {
            dispatch(setCategoryItems(items))
        }))
    }
    return (
        <React.Fragment>
            <div className="card-panel mb-3">
                <div className="row">
                    {loading ? <Skeleton count={1} height={50} /> : (
                        <div className="input-field col m12 s12">
                            <Select
                                name="id_cat"
                                id="id_cat"
                                value={categoryOptions.filter(opt => { return (opt.value ===  categoryId  ) })}
                                onChange={(opt, e) => {
                                    handelCategoryChange(opt?.value)
                                }}
                                blurInputOnSelect={true}
                                placeholder=""
                                isSearchable={true}
                                isClearable={true}
                                styles={customStyles}
                                options={categoryOptions}
                            />
                            <label htmlFor="categorie" className="active">Catégorie sélectionnée</label>
                        </div>
                    )}
                    <Link to="/categories" className="mb-3 mt-3 col m12 s12"><i className="material-icons left">add_circle</i>Ajouter categorie</Link>
                </div>
            </div>
        </React.Fragment>
    )
}
