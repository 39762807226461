const { AUTHORIZED_APPS_LIST_REQUEST,
    AUTHORIZED_APPS_LIST_SUCCESS,
    AUTHORIZED_APPS_LIST_FAIL,
    AUTHORIZED_APPS_SAVE_REQUEST,
    CURRENT_AUTHORIZED_APP_REQUEST,
    CURRENT_AUTHORIZED_APP_SUCCESS,
    CURRENT_AUTHORIZED_APP_FAIL,
    AUTHORIZED_APPS_SAVE_SUCCESS,
    AUTHORIZED_APPS_SAVE_FAIL,
    AUTHORIZED_APPS_DELETE_REQUEST,
    AUTHORIZED_APPS_DELETE_SUCCESS,
    AUTHORIZED_APPS_DELETE_FAIL,
    SELECTED_AUTHORIZED_APP,
    AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED,
    AUTHORIZED_APPS_RESTORE_REQUEST,
    AUTHORIZED_APPS_RESTORE_SUCCESS,
    AUTHORIZED_APPS_RESTORE_FAIL,
    AUTHORIZED_APPS_DESTROY_REQUEST,
    AUTHORIZED_APPS_DESTROY_SUCCESS,
    AUTHORIZED_APPS_DESTROY_FAIL
} = require("../constants/authorizedAppsConstants");

function authorizdAppsListReducer(state = { loading: true, authorizdApps: [] }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_LIST_REQUEST:
            return { loading: true };
        case AUTHORIZED_APPS_LIST_SUCCESS:
            return { loading: false, authorizdApps: action.payload };
        case AUTHORIZED_APPS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function authorizdAppsSaveReducer(state = { authorizdApps: [] }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_SAVE_REQUEST:
            return { loading: true };
        case AUTHORIZED_APPS_SAVE_SUCCESS:
            return { loading: false, success: true, authorizdApps: action.payload };
        case AUTHORIZED_APPS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function currentAuthorizedAppReducer(state = { currentLang: [] }, action) {

    switch (action.type) {
        case CURRENT_AUTHORIZED_APP_REQUEST:
            return { loading: true };
        case CURRENT_AUTHORIZED_APP_SUCCESS:
            return { loading: false, success: true, currentLang: action.payload };
        case CURRENT_AUTHORIZED_APP_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function authorizdAppsDeleteReducer(state = { authorizedApp: [] }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_DELETE_REQUEST:
            return { loading: true };
        case AUTHORIZED_APPS_DELETE_SUCCESS:
            return { loading: false, success: true, authorizedApp: action.payload };
        case AUTHORIZED_APPS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function authorizdAppsRestoreReducer(state = { authorizedApp: [] }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_RESTORE_REQUEST:
            return { loading: true };
        case AUTHORIZED_APPS_RESTORE_SUCCESS:
            return { loading: false, success: true, authorizedApp: action.payload };
        case AUTHORIZED_APPS_RESTORE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}


function destroyAuthorizedAppReducer(state = { authorizedApp: [] }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_DESTROY_REQUEST:
            return { loading: true };
        case AUTHORIZED_APPS_DESTROY_SUCCESS:
            return { loading: false, success: true, authorizedApp: action.payload };
        case AUTHORIZED_APPS_DESTROY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedAuthorizedAppReducer(state = { authorizedApp: null }, action) {

    switch (action.type) {
        case SELECTED_AUTHORIZED_APP:
            return { authorizedApp: action.payload };
        default:
            return state;
    }

}

function toggleAuthorizedAppsSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case AUTHORIZED_APPS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { authorizdAppsListReducer, authorizdAppsSaveReducer, authorizdAppsDeleteReducer, selectedAuthorizedAppReducer, toggleAuthorizedAppsSidebarReducer, currentAuthorizedAppReducer, authorizdAppsRestoreReducer, destroyAuthorizedAppReducer }