export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const IS_LOGGED_IN_REQUEST = 'IS_LOGGED_IN_REQUEST';
export const IS_LOGGED_IN_SUCCESS = 'IS_LOGGED_IN_SUCCESS';
export const IS_LOGGED_IN_FAIL = 'IS_LOGGED_IN_FAIL';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VERIFY_RESET_PASSWORD_LINK_REQUEST = 'VERIFY_RESET_PASSWORD_LINK_REQUEST';
export const VERIFY_RESET_PASSWORD_LINK_SUCCESS = 'VERIFY_RESET_PASSWORD_LINK_SUCCESS';
export const VERIFY_RESET_PASSWORD_LINK_FAIL = 'VERIFY_RESET_PASSWORD_LINK_FAIL';