export const LANGUAGES_LIST_REQUEST = 'LANGUAGES_LIST_REQUEST';
export const LANGUAGES_LIST_SUCCESS = 'LANGUAGES_LIST_SUCCESS';
export const LANGUAGES_LIST_FAIL = 'LANGUAGES_LIST_FAIL';

export const LANGUAGES_SAVE_REQUEST = 'LANGUAGES_SAVE_REQUEST';
export const LANGUAGES_SAVE_SUCCESS = 'LANGUAGES_SAVE_SUCCESS';
export const LANGUAGES_SAVE_FAIL = 'LANGUAGES_SAVE_FAIL';

export const LANGUAGES_DELETE_REQUEST = 'LANGUAGES_DELETE_REQUEST';
export const LANGUAGES_DELETE_SUCCESS = 'LANGUAGES_DELETE_SUCCESS';
export const LANGUAGES_DELETE_FAIL = 'LANGUAGES_DELETE_FAIL';

export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';

export const LANGUAGES_SIDEBAR_IS_TOGGLED = 'LANGUAGES_SIDEBAR_IS_TOGGLED';

export const CURRENT_LANGUAGE_REQUEST = 'CURRENT_LANGUAGE_REQUEST';
export const CURRENT_LANGUAGE_SUCCESS = 'CURRENT_LANGUAGE_SUCCESS';
export const CURRENT_LANGUAGE_FAIL = 'CURRENT_LANGUAGE_FAIL';

export const   LANGUAGES_RESTORE_REQUEST = '  LANGUAGES_RESTORE_REQUEST';
export const   LANGUAGES_RESTORE_SUCCESS = '    LANGUAGES_RESTORE_SUCCESS';
export const   LANGUAGES_RESTORE_FAIL  = '    LANGUAGES_RESTORE_FAIL';

export const LANGUAGES_DESTROY_REQUEST = 'LANGUAGES_DESTROY_REQUEST';
export const LANGUAGES_DESTROY_SUCCESS = 'LANGUAGES_DESTROY_SUCCESS';
export const LANGUAGES_DESTROY_FAIL = 'LANGUAGES_DESTROY_FAIL';