const { USERS_LIST_REQUEST, 
        USERS_LIST_SUCCESS, 
        USERS_LIST_FAIL,
        USERS_SAVE_REQUEST,
        USERS_SAVE_SUCCESS,
        USERS_SAVE_FAIL, 
        USERS_DELETE_REQUEST,
        USERS_DELETE_SUCCESS,
        USERS_DELETE_FAIL,
        SELECTED_USER,
        USERS_SIDEBAR_IS_TOGGLED,
        USER_AUTH_PERMISSIONS_REQUEST,
        USER_AUTH_PERMISSIONS_SUCCESS,
        USER_AUTH_PERMISSIONS_FAIL
    } = require("../constants/usersConstants");

function usersListReducer(state = {loading:true, users:[]}, action){

    switch(action.type){
        case USERS_LIST_REQUEST:
            return {loading : true};
        case USERS_LIST_SUCCESS:
            return {loading: false, users: action.payload};
        case USERS_LIST_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function userAuthPermissionsReducer(state = {loading:true, userAuthPermissions:[]}, action){

    switch(action.type){
        case USER_AUTH_PERMISSIONS_REQUEST:
            return {loading : true};
        case USER_AUTH_PERMISSIONS_SUCCESS:
            return { loading: false, userAuthPermissions: action.payload};
        case USER_AUTH_PERMISSIONS_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function usersSaveReducer(state = {users:[]}, action){

    switch(action.type){
        case USERS_SAVE_REQUEST:
            return {loading : true};
        case USERS_SAVE_SUCCESS:
            return {loading: false, success: true, users: action.payload};
        case USERS_SAVE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function usersDeleteReducer(state = {users:[]}, action){

    switch(action.type){
        case USERS_DELETE_REQUEST:
            return {loading : true};
        case USERS_DELETE_SUCCESS:
            return {loading: false, success: true, users: action.payload};
        case USERS_DELETE_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }

}

function selectedUserReducer(state = { user: null }, action) {

    switch (action.type) {
        case SELECTED_USER:
            return { user: action.payload };
        default:
            return state;
    }

}

function toggleUsersSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case USERS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { usersListReducer, usersSaveReducer, usersDeleteReducer, selectedUserReducer, toggleUsersSidebarReducer, userAuthPermissionsReducer}