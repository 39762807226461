import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { POSTS_LIST_REQUEST, 
    POSTS_LIST_SUCCESS, 
    POSTS_LIST_FAIL,
    POSTS_SAVE_REQUEST,
    POSTS_SAVE_SUCCESS,
    POSTS_SAVE_FAIL, 
    POSTS_DELETE_REQUEST,
    POSTS_DELETE_SUCCESS,
    POSTS_DELETE_FAIL,
    SELECTED_POSTS,
    POSTS_LANG_REQUEST,
    POSTS_LANG_SUCCESS,
    POSTS_LANG_FAIL,
    POST_RESTORE_REQUEST,
    POST_RESTORE_SUCCESS,
    POST_RESTORE_FAIL,
    POST_DESTROY_REQUEST,
    POST_DESTROY_SUCCESS,
    POST_DESTROY_FAIL,
    SELECTED_POST_CATEGORIES_ITEMS,
    POSTS_MENU_LIST_REQUEST,
    POSTS_MENU_LIST_SUCCESS,
    POSTS_MENU_LIST_FAIL
} = require("../constants/postsConstants");

const listPosts = (postType,cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/posts/post-type/" + postType,
            headers,
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTS_LIST_FAIL, payload: error.message });
    }
    
}

const listMenuPosts = (postType,id_lang,cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTS_MENU_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/posts/post-type/menu/" + postType + "/" + id_lang,
            headers,
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTS_MENU_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type:POSTS_MENU_LIST_FAIL, payload: error.message });
    }
    
}

const listTrashedPosts = (postType,cb) => async (dispatch) => {
    try {
        dispatch({ type: POSTS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/posts/post-type/trashed/" + postType,
            headers,
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: POSTS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTS_LIST_FAIL, payload: error.message });
    }
    
}


const restorePost = (post, callback) => async (dispatch) => {
    try {
        dispatch({ type: POST_RESTORE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy+"/api/posts/restore/" + post.id,
            headers,
        });
        callback(data);
        dispatch({ type: POST_RESTORE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POST_RESTORE_FAIL, payload: error.message });
    }

}


const destroyPost = (post, callback) => async (dispatch) => {
    try {
        dispatch({ type: POST_DESTROY_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/posts/destroy/" + post.id,
            headers,
        });
        callback(data);
        dispatch({ type: POST_DESTROY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POST_DESTROY_FAIL, payload: error.message });
    }

}

const savePosts = (posts, callback) => async (dispatch) => {

    try {

        if(!posts.id){
            dispatch({ type: POSTS_SAVE_REQUEST, payload: {posts} });
            const { data } = await axios({
                method: 'post',
                url: apiProxy+"/api/posts",
                headers,
                data: posts
            });
            callback(data);
            dispatch({ type: POSTS_SAVE_SUCCESS, payload: data });
        }else{
            dispatch({ type: POSTS_SAVE_REQUEST, payload: {posts} });
            const { data } = await axios({
                method: 'patch',
                url: apiProxy+"/api/posts/" + posts.id,
                headers,
                data: posts
            });
            callback(data);
            dispatch({ type: POSTS_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: POSTS_SAVE_FAIL, payload: error.message });
    }
    
}

const deletePosts = (posts, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/posts/" + posts.id,
            headers
        });
        callback(data);
        dispatch({ type: POSTS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTS_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedPost = (posts) => async (dispatch) => {
    dispatch({ type: SELECTED_POSTS, payload: posts });
}

const setSelectedPostCategorieItems = (categoriesItems) => async (dispatch) => {
    dispatch({ type: SELECTED_POST_CATEGORIES_ITEMS, payload: categoriesItems });
}

const postByLang = (postId, langId, callback) => async (dispatch) => {
    try {
        dispatch({ type: POSTS_LANG_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/posts/" + postId + "/" + langId,
            headers
        });
        callback(data);
        dispatch({ type: POSTS_LANG_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: POSTS_LANG_FAIL, payload: error.message });
    }
    
}

export { listPosts, listMenuPosts, savePosts, deletePosts, selectedPost, postByLang, listTrashedPosts, restorePost, destroyPost, setSelectedPostCategorieItems}
