import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listAuthorizedApps, deleteAuthorizedApp, selectedAuthorizedApp, toggleAuthorizedAppsSidebar, restoreAuthorizedApp, destroyAuthorizedApp} from '../../actions/authorizedAppsActions';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import Loading from '../loader/Loading'
export default function LanguagesDatatable() {

    const dispatch = useDispatch();
    let history = useHistory();
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);


    const { authorizdApps, loading, error } = useSelector(state => state.authorizedAppsList);


    const { loading: loadingDelete, success: successDelete,error: errorDelete } = useSelector(state => state.authorizedAppsDelete);

    const { loading : loadingSave,  success: successSave, error: errorSave } = useSelector(state => state.authorizedAppsSave);


    const columns = [
        {
            name: 'Nom de l\'Application',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Nom de domaine',
            selector: 'host',
            sortable: true,
        },
        {
            name: 'Autorisé',
            selector: 'is_allowed',
            sortable: false,
            cell: (app) => app.is_allowed ? <div className="card-alert card green">
                <div className="card-content white-text">
                    <p>Oui</p>
                </div>
            </div> : <div className="card-alert card red">
                    <div className="card-content white-text">
                        <p>Non</p>
                    </div>
                </div>,
        },
        {
            name: 'Copie le token',
            selector: 'is_allowed',
            sortable: false,
            cell: (app) => < CopyToClipboard text={ app.token }
                     onCopy={
                         () => toast.success('👌Token copié')
                    }>
                <button className="btn-small gradient-45deg-blue-grey-blue"  data-for="copy_token" data-iscapture="true" data-tip="cliquez pour copier le token">
                    <ReactTooltip place="bottom" id="copy_token" type="dark" effect="solid" />

                    <i className="material-icons">content_copy</i></button>
            </ CopyToClipboard>,
        },
        {
            cell: (app) => !app.deleted_at ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editAuthorizedAppHandler(app); }}>
                <i className="material-icons">edit</i>
            </button> : <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyAuthorizedAppHandler(app); }}>
                    <i className="material-icons" style={{ color: "#d32f2f"}}>delete_forever</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (app) => !app.deleted_at ?  <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deleteAuthorizedAppHandler(app); } }>
                <i className="material-icons">delete</i>
            </button> : <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restoreAuthorizedAppHandler(app); }}>
                    <i className="material-icons">restore</i>
                </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(authorizdApps);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listAuthorizedApps());
        }

    }, [successSave, deleteSuccess, authorizdApps]);

    const editAuthorizedAppHandler = ( appData ) => {
        dispatch(selectedAuthorizedApp(appData));
        dispatch(toggleAuthorizedAppsSidebar(true));
    }
    const restoreAuthorizedAppHandler = ( lang ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Restaurer'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restoreAuthorizedApp(lang, (response) => {
                    history.push('/authorized-apps')
                    dispatch(listAuthorizedApps());
                    Swal.fire(
                        'Restaurer',
                        'Application bien Restaurer.',
                        'success'
                    )
                }));

            }
        })
       
    }
    const destroyAuthorizedAppHandler = ( lang ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'supprimer définitivement'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyAuthorizedApp(lang, (response) => {
                    history.push('/authorized-apps')
                    dispatch(listAuthorizedApps());
                    Swal.fire(
                        'supprimer définitivement',
                        'Application bien supprimer.',
                        'success'
                    )
                }));

            }
        })
       
    }

    const deleteAuthorizedAppHandler = ( appData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteAuthorizedApp(appData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Applications bien supprimer.',
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = authorizdApps.filter(item => item.name && item.name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div><Loading/></div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 Languages-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}

