import React from 'react'
import PostTemplatesCount from './PostTemplatesCount'
import { listPostTemplates, listTrashedTemplates } from '../../actions/postTemplatesActions'
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'

export default function PostTemplatesFiltre({ props } ) {
    let history = useHistory();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            {/* <!-- Sidebar Area Starts --> */}
            <div className="sidebar-left sidebar-fixed">
                <div className="sidebar">
                    <div className="sidebar-content">
                        <div className="sidebar-header">
                            <div className="sidebar-details">
                                <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">view_compact</i> PostTemplates
                            </h5>
                            <div className="mt-10 pt-2">
                                <p className="m-0 subtitle font-weight-700">Number de Post Templates</p>
                                <PostTemplatesCount />
                            </div>
                            </div>
                        </div>
                        <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                            <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                                <ul className="contact-list display-grid">
                                    <li className="sidebar-title">Filters</li>

                                                <li className={typeof props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                                                    dispatch(listPostTemplates(() => {
                                                        history.push('/post-templates')
                                                    }))
                                                }}><a className="text-sub" ><i className="material-icons mr-2">
                                            view_compact </i> Tout les Template</a>
                                    </li>

                                    <li className={typeof props.match.params.trush !== "undefined" ? "active" : ""} onClick={() => {
                                        dispatch(listTrashedTemplates(() => {
                                            history.push('/post-templates/trush')
                                        }))
                                    }}><a className="text-sub" ><i className="material-icons mr-2">
                                    delete_sweep </i> Corbeille</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                        className="material-icons">menu</i></a>
                    </div>
                </div>
            </div>
            {/* <!-- Sidebar Area Ends --> */}
        </React.Fragment>
    );
}