export const POSTS_LIST_REQUEST = 'POSTS_LIST_REQUEST';
export const POSTS_LIST_SUCCESS = 'POSTS_LIST_SUCCESS';
export const POSTS_LIST_FAIL = 'POSTS_LIST_FAIL';

export const POSTS_SAVE_REQUEST = 'POSTS_SAVE_REQUEST';
export const POSTS_SAVE_SUCCESS = 'POSTS_SAVE_SUCCESS';
export const POSTS_SAVE_FAIL = 'POSTS_SAVE_FAIL';

export const POSTS_DELETE_REQUEST = 'POSTS_DELETE_REQUEST';
export const POSTS_DELETE_SUCCESS = 'POSTS_DELETE_SUCCESS';
export const POSTS_DELETE_FAIL = 'POSTS_DELETE_FAIL';

export const POSTS_LANG_REQUEST = 'POSTS_LANG_REQUEST';
export const POSTS_LANG_SUCCESS = 'POSTS_LANG_SUCCESS';
export const POSTS_LANG_FAIL = 'POSTS_LANG_FAIL';

export const SELECTED_POSTS = 'SELECTED_POSTS';

export const POST_RESTORE_REQUEST = 'POST_RESTORE_REQUEST';
export const POST_RESTORE_SUCCESS = 'POST_RESTORE_SUCCESS';
export const POST_RESTORE_FAIL = 'POST_RESTORE_FAIL';


export const POST_DESTROY_REQUEST = 'POST_DESTROY_REQUEST';
export const POST_DESTROY_SUCCESS = 'POST_DESTROY_SUCCESS';
export const POST_DESTROY_FAIL = 'POST_DESTROY_FAIL';

export const SELECTED_POST_CATEGORIES_ITEMS = 'SELECTED_POST_CATEGORIES_ITEMS';

export const POSTS_MENU_LIST_REQUEST = 'POSTS_MENU_LIST_REQUEST';
export const POSTS_MENU_LIST_SUCCESS = '    POSTS_MENU_LIST_SUCCESS';
export const POSTS_MENU_LIST_FAIL = '    POSTS_MENU_LIST_FAIL';
