const { MENUS_LIST_REQUEST,
    MENUS_LIST_SUCCESS,
    MENUS_LIST_FAIL,
    MENUS_SAVE_REQUEST,
    MENUS_SAVE_SUCCESS,
    MENUS_SAVE_FAIL,
    MENUS_DELETE_REQUEST,
    MENUS_DELETE_SUCCESS,
    MENUS_DELETE_FAIL,
    SELECTED_MENU,
    MENUS_SIDEBAR_IS_TOGGLED 
} = require("../constants/menusConstants");

function menusListReducer(state = { loading: true, menus: [] }, action) {

    switch (action.type) {
        case MENUS_LIST_REQUEST:
            return { loading: true };
        case MENUS_LIST_SUCCESS:
            return { loading: false, menus: action.payload };
        case MENUS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function menuSaveReducer(state = { menu: [] }, action) {

    switch (action.type) {
        case MENUS_SAVE_REQUEST:
            return { loading: true };
        case MENUS_SAVE_SUCCESS:
            return { loading: false, success: true, menu: action.payload };
        case MENUS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function menuDeleteReducer(state = { menu: [] }, action) {

    switch (action.type) {
        case MENUS_DELETE_REQUEST:
            return { loading: true };
        case MENUS_DELETE_SUCCESS:
            return { loading: false, success: true, menu: action.payload };
        case MENUS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function selectedMenuReducer(state = { menu: null }, action) {

    switch (action.type) {
        case SELECTED_MENU:
            return { menu: action.payload };
        default:
            return state;
    }

}

function toggleMenusSidebarReducer(state = { toggle: false }, action) {

    switch (action.type) {
        case MENUS_SIDEBAR_IS_TOGGLED:
            return { toggle: action.payload };
        default:
            return state;
    }

}


export { menusListReducer, menuSaveReducer, menuDeleteReducer, selectedMenuReducer, toggleMenusSidebarReducer }