import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    MENU_ITEMS_ADD_SUCCESS,
    MENU_ITEMS_SAVE_REQUEST,
    MENU_ITEMS_SAVE_SUCCESS,
    MENU_ITEMS_SAVE_FAIL,
    MENU_ITEMS_LIST_REQUEST,
    MENU_ITEMS_LIST_SUCCESS,
    MENU_ITEMS_LIST_FAIL,
    MENU_ID_SET_SUCCESS,
    MENU_Lang_ID_SET_SUCCESS
} = require("../constants/menuItemsConstants");



const listMenuItems = (id_menu, id_lang,cb) => async (dispatch) => {
    try {
        dispatch({ type:MENU_ITEMS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/menu_items/' + id_menu + '/' + id_lang,
            headers
        });
        typeof cb == "function" && cb(data);
        dispatch({ type:MENU_ITEMS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type:MENU_ITEMS_LIST_FAIL, payload: error.message });
    }

}

const setMenuItems = (items) => async (dispatch) => { 
    dispatch({ type: MENU_ITEMS_ADD_SUCCESS, payload: items  });
}

const setMenuId = (id) => async (dispatch) => { 
    dispatch({ type: MENU_ID_SET_SUCCESS, payload: id  });
}

const setMenuLangId = (id) => async (dispatch) => { 
    dispatch({ type: MENU_Lang_ID_SET_SUCCESS, payload: id  });
}


const saveMenuItem = (dadaForm, callback) => async (dispatch) => {


    dispatch({ type: MENU_ITEMS_SAVE_REQUEST, payload: { dadaForm } });
    axios({
        method: 'post',
        url: apiProxy+"/api/menu_items",
        headers,
        data: dadaForm
    }).then(data => {
        callback({
            error: false,
            data: data,
            message: null
        });
        dispatch({ type: MENU_ITEMS_SAVE_SUCCESS, payload: data });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data.message
        });
        dispatch({ type: MENU_ITEMS_SAVE_FAIL, payload: err.response.data.message });
    });;



}

export { setMenuItems, saveMenuItem, listMenuItems, setMenuId, setMenuLangId}