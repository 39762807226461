import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function PostsTypeCount(  ) {

    const postTypeList = useSelector(state => state.postTypeList);
    const { postType, 
            loading,
            error } = postTypeList;

    useEffect(() => {
        
    }, [postType]);     

    return (
        <React.Fragment>
            {loading ? <div>Loading...</div> :  
                error ? <div>{error}</div> : 
                
                <p className="m-0 text-muted">{postType.length} post type</p>
            }
        </React.Fragment>
    );
}