import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';

import { listMenus, saveMenu, selectedMenu, toggleMenusSidebar } from '../../actions/menusActions';

const CreateMenuSchema = Yup.object().shape({
    menus_title: Yup.string().min(3, Errors.min({ name: "le titre", number: "3" })).required( Errors.required({ name: "le titre"}) ),
})

export default function MenusFormSidebar() {

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state : false,
        message : null
    });

    const [id, setId] = useState('');
    const [titre, setTitle] = useState('');


    const menusToggleSidebar = useSelector(state => state.toggleMenusSidebar);

    const menuSelected = useSelector(state => state.selectedMenu);

    const menuSave = useSelector(state => state.menuSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = menuSave;

    
    useEffect(() => {

        console.log(menuSelected);
       if(menuSelected.menu){
            setId(menuSelected.menu.id);
            setTitle(menuSelected.menu.menus_title);
        }else{
            setId('');
            setTitle('');
        }

        
        if(saveSuccess){
            setSaveSuccess(false);
            dispatch(listMenus());
            toast.success('Menu Enregistrer');
        }
        if (saveFail.state){
            toast.error(saveFail.message);
            dispatch(listMenus());
            setSaveFail({
                state: false,
                message: null
            });
        }

    }, [menuSelected, saveSuccess, saveFail]);
    



    const closeFormSidebar = () => {
        dispatch( selectedMenu(null) );
        dispatch( toggleMenusSidebar(false) );
    }


    return <React.Fragment>
        {loadingSave && <div>loading ...</div> }
        {errorSave && <div>{errorSave}</div> }

            <div className={menusToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
            <div className="card-content pt-0">
            <div className="card-header display-flex pb-2">
                <h3 className="card-title contact-title-label">{id === '' ? "Create New Menu" : "Update Menu"}</h3>
                <div className="close close-icon" onClick={closeFormSidebar}>
                    <i className="material-icons">close</i>
                </div>
            </div>
            <div className="divider"></div> 

            <Formik
                initialValues={{
                    id: id ? id : '',
                    menus_title: titre ? titre : '',
                }}
                validationSchema={CreateMenuSchema}
                enableReinitialize={true}
                onSubmit={async (values, {resetForm}) => {
                    closeFormSidebar();
                    dispatch(saveMenu(values, (response) => {
                        response.error ? setSaveFail({
                            state: true,
                            message: response.message
                        }) : setSaveSuccess(true);
                    }));
                    resetForm({values: ''});
                }}
            >
            {({ values, errors, touched, isSubmitting }) => (
                    <Form className="edit-contact-item mb-5 mt-5">
                        <div className="row">
                            <div className="input-field col s12">
                                <Field id="menus_title" name="menus_title" type="text"  className={errors.menus_title ? "error validate" : "validate"} />
                                <label htmlFor="menus_title" className={titre !== '' ? 'active' : ''}>Titre</label>
                                {errors.menus_title && touched.menus_title ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.menus_title}</div></small>
                                ) : null}
                            </div>
                        </div>
                      

                        <div className="card-action pl-0 pr-0 right-align">
                            {id === '' ? (
                                <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                    <span>Add Menu</span>
                                </button>
                            ) : (
                                <button type="submit" className="btn-small waves-effect waves-light amber darken-4 update-contact" disabled={isSubmitting}>
                                    <span>Update Menu</span>
                                </button>
                            )}
                        </div>

                    </Form>
                )}

            </Formik>

            </div>
            </div>
            </div>
        </React.Fragment> ;
}