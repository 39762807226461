import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function LanguagesCount( props ) {

    const languagesList = useSelector(state => state.languagesList);
    const { languages, 
            loading,
            error } = languagesList;

    useEffect(() => {
        
    }, [languages]);     

    return (
        <React.Fragment>
            {loading ? <div>Loading...</div> :  
                error ? <div>{error}</div> : 
                
                    <p className="m-0 text-muted">{languages.length} Languages</p>
            }
        </React.Fragment>
    );
}