import React, { useEffect, useState } from 'react'

// packages 
import { useSelector, useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { Field } from 'formik';

// actions 
// import { listPostType } from '../../../actions/postTypeActions'
import { listMenuPosts } from '../../../actions/postsActions';


export default function PostField(props) {
    const dispatch = useDispatch();
    const postTypeList = useSelector(state => state.postTypeList);
    const { postType: postTypes , loading, error } = postTypeList;
    const menuPosts = useSelector(state => state.menuPosts);
    const { menuLangId } = useSelector(state => state.menuLangSelected);
    const { posts,
        loading :postsLoading,
        error : postsError } = menuPosts;
    

    let item = props.item;
    let output = "LINK";
    if (item) {
        output = item.output
    }
    const [title, setTitle] = useState("");
    const [postType, setPostType] = useState("");
    const [className, setClassName] = useState("");
    const [dataType, setDataType] = useState(output);
    const [payload, setPayload] = useState("");
    const [postsList, setPostsList] = useState([]);
    // const [posts, setPosts] = useState([]);



    useEffect(() => {

        if (item?.post_type?.length && postTypes.length && !posts?.length && !postsLoading) {
            const postSelected = postTypes.filter(post => post.post_type_title === item.post_type)[0];
            if (postSelected) {
                dispatch(listMenuPosts(postSelected.id, menuLangId));
            }

        }
        window.initPlugins();
        // dispatch(listPostType());
        setTitle(item.title);
        setClassName(item.className);
        setDataType(item.output);
        setPayload(item.payload);
        setPostType(item.post_type);
    
    }, [item, posts]);


  

    return (
        <React.Fragment>
            <div className="collapsible-body">
                <div className="row">
                    {
                        !loading ? (
                            postTypes.length ? (
                                <React.Fragment>
                                    <div className="input-field col m12 s12">
                                        <Field name={`items[${props.index}].title`} value={title} type="text" onChange={(e) => {
                                            item.title = e.target.value;
                                            setTitle(e.target.value);
                                        }} />
                                        <label htmlFor="name" className={title ? "active" : ""}>Titre</label>
                                    </div>
                                    <div className="input-field col m12 s12">
                                        <Field name={`items[${props.index}].className`} type="text" value={className} onChange={(e) => {
                                            item.className = e.target.value;
                                            setClassName(e.target.value);
                                        }} />
                                        <label htmlFor="name" className={className ? "active" : ""}>Class attribute</label>
                                    </div>
                                    <div className="input-field col s12">
                                        <p className="mb-2">Data type</p>
                                        <p className="mb-1 display-inline mr-3">
                                            <label>
                                                <Field name={`items[${props.index}].output`} checked={dataType === "LINK"} type="radio" value="LINK" onChange={
                                                    (e) => {
                                                        item.output = e.target.value;
                                                        setDataType('LINK')
                                                    }
                                                } />
                                                <span>Url simple</span>
                                            </label>
                                        </p>
                                        <p className="mb-1 display-inline">
                                            <label>
                                                <Field name={`items[${props.index}].output`} checked={dataType === "OBJECT"} type="radio" value="OBJECT" onChange={
                                                    (e) => {
                                                        item.output = e.target.value;
                                                        setDataType('OBJECT')
                                                    }
                                                } />
                                                <span>Data Object</span>
                                            </label>
                                        </p>

                                    </div>
                                    <div className="input-field col m12 s12">
                                        <Field component="select" value={postType} name={`items[${props.index}].post_type`} onChange={(e) => {
                                            setPostType(e.target.value);
                                            item.post_type = e.target.value;
                                            dispatch(listMenuPosts(e.target[e.target.selectedIndex].getAttribute('data-id'), menuLangId));
                                        }}>
                                            <option value="" key="0" >Select type de post</option>
                                            {
                                                postTypes.map(function (item, i) {
                                                    return <option data-id={item.id} value={item.post_type_title} key={i + 1} >{item.post_type_title}</option>
                                                })
                                            }
                                        </Field>
                                        <label htmlFor="post_type" className='active'>Type publication</label>
                                    </div>
                                    {
                                        postsLoading ? (<Skeleton count={1} height={50} />) :
                                            posts?.length ? (
                                                <div className="input-field col m12 s12">
                                                    <Field component="select" value={payload} name={`items[${props.index}].payload`} onChange={(e) => {
                                                        item.payload = e.target.value;
                                                        setPayload(e.target.value);
                                                    }}>
                                                        <option value="" key="0" >Select Post</option>
                                                        {
                                                            posts.map(function (item, i) {
                                                                return <option value={dataType === "LINK" ? item.slug : JSON.stringify(item)} key={i + 1} >{item.title}</option>
                                                            })
                                                        }
                                                    </Field>
                                                    <label htmlFor="posts" className='active'>Post</label>
                                                </div>
                                            ) : (
                                                    postType ? (
                                                        <div className="input-field col m12 s12">
                                                            <div className="card-panel red  center">
                                                                <span className="white-text">Aucun type de publication { postType } trouvé <i className="material-icons">error_outline</i></span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                            <div className="input-field col m12 s12">
                                                                <div className="card-panel green  center">
                                                                    <span className="white-text"> sélectionnez le type de publication <i className="material-icons">view_list</i></span>
                                                                </div>
                                                            </div>
                                                        )
                                            
                                            )
                                    }


                                </React.Fragment>

                            ) : (
                                    <div className="card-panel red  center">
                                        <span className="white-text">Aucun article trouvé <i className="material-icons">error_outline</i></span>
                                    </div>
                                )
                        ) : (
                            <Skeleton count={4} height={50} />
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
