import React from 'react';
import AuthorizedAppsCount from './AuthorizedAppsCount';
import { listTrashedAuthorizedApps, listAuthorizedApps } from '../../actions/authorizedAppsActions';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
export default function AuthorizedAppsFiltre({props}) {
    const dispatch = useDispatch();
    let history = useHistory();
    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                                <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">security</i> Applications
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">Number des Applications</p>
                    <AuthorizedAppsCount />
                </div>
                </div>
            </div>
            <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                <ul className="contact-list display-grid">
                    <li className="sidebar-title">Menu</li>

                    <li className={typeof props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                        dispatch(listAuthorizedApps(() => {
                            history.push('/authorized-apps')
                        }))
                        }}><a className="text-sub"><i className="material-icons mr-2">
                        apps </i> Tout les Applications</a>
                    </li>

                    <li className={typeof props.match.params.trush !== "undefined" ? "active" : ""} onClick={() => {
                        dispatch(listTrashedAuthorizedApps(() => {
                            history.push('/authorized-apps/trush')
                        }))
                    }}><a className="text-sub"><i className="material-icons mr-2">
                        delete_sweep </i> Corbeille</a>
                    </li>
                    
                </ul>
                </div>
            </div>
            <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                className="material-icons">menu</i></a>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}