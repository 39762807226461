const { ROLES_LIST_REQUEST, 
    ROLES_LIST_SUCCESS, 
    ROLES_LIST_FAIL,
    ROLES_SAVE_REQUEST,
    ROLES_SAVE_SUCCESS,
    ROLES_SAVE_FAIL, 
    ROLES_DELETE_REQUEST,
    ROLES_DELETE_SUCCESS,
    ROLES_DELETE_FAIL,
    SELECTED_ROLE,
    ROLES_SIDEBAR_IS_TOGGLED} = require("../constants/rolesConstants");

function rolesListReducer(state = {loading:true, roles:[]}, action){

switch(action.type){
    case ROLES_LIST_REQUEST:
        return {loading : true};
    case ROLES_LIST_SUCCESS:
        return {loading: false, roles: action.payload};
    case ROLES_LIST_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function rolesSaveReducer(state = {roles:[]}, action){

switch(action.type){
    case ROLES_SAVE_REQUEST:
        return {loading : true};
    case ROLES_SAVE_SUCCESS:
        return {loading: false, success: true, roles: action.payload};
    case ROLES_SAVE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function rolesDeleteReducer(state = {roles:[]}, action){

switch(action.type){
    case ROLES_DELETE_REQUEST:
        return {loading : true};
    case ROLES_DELETE_SUCCESS:
        return {loading: false, success: true, roles: action.payload};
    case ROLES_DELETE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function selectedRoleReducer(state = { role: null }, action) {

switch (action.type) {
    case SELECTED_ROLE:
        return { role: action.payload };
    default:
        return state;
}

}

function toggleRolesSidebarReducer(state = { toggle: false }, action) {

switch (action.type) {
    case ROLES_SIDEBAR_IS_TOGGLED:
        return { toggle: action.payload };
    default:
        return state;
}

}


export {rolesListReducer, rolesSaveReducer, rolesDeleteReducer, selectedRoleReducer, toggleRolesSidebarReducer}