import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify'
import { pathListner } from '../../actions/pathActions';
import { listLanguages, currentLanguage} from '../../actions/languagesActions';
import { locosList } from '../../actions/locoActions';
import LocoTranslateForm from './LocoTranslateForm'
import AddLocoForm from './AddLocoForm'
export default function Loco() {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pathListner('/loco-translate'));
        dispatch(listLanguages());
        dispatch(locosList());
        dispatch(currentLanguage());
    }, []);

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                   
                    <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                         <div className="container">
                            <div className="row">
                                <div className="col s10 m6 l6">
                                    <h5 className="breadcrumbs-title mt-0 mb-0"><span>Local traduction </span></h5>
                                    <ol className="breadcrumbs mb-0">
                                        <li className="breadcrumb-item"><a href="#">Langages</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#">loco translate</a>
                                        </li>
                                        <li className="breadcrumb-item active">locos </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="seaction">
                            <div className="card">
                                <div className="card-content">
                                    <p className="caption mb-0">Ici vous pouvez voir tous les courts textes de votre site Web.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m6 l6">
                                <div  className="card card card-default scrollspy">
                                    {/* form  */}
                                    <LocoTranslateForm/>
                                    {/* form  */}

                                </div>
                            </div>
                            <div className="col s12 m6 l6">
                                <div className="card card card-default scrollspy">
                                    {/* form  */}
                                    <AddLocoForm />
                                    {/* form  */}

                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

    </React.Fragment>;
}
