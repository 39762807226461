import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { ROLES_LIST_REQUEST, 
    ROLES_LIST_SUCCESS, 
    ROLES_LIST_FAIL,
    ROLES_SAVE_REQUEST,
    ROLES_SAVE_SUCCESS,
    ROLES_SAVE_FAIL, 
    ROLES_DELETE_REQUEST,
    ROLES_DELETE_SUCCESS,
    ROLES_DELETE_FAIL,
    SELECTED_ROLE,
    ROLES_SIDEBAR_IS_TOGGLED} = require("../constants/rolesConstants");

const listRoles = () => async (dispatch) => {
    try {
        dispatch({ type: ROLES_LIST_REQUEST });
        const { data } = await axios({
                method: 'get',
                url: apiProxy+"/api/roles",
                headers
            });
        dispatch({ type: ROLES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ROLES_LIST_FAIL, payload: error.message });
    }
    
}

const saveRole = (role, callback) => async (dispatch) => {

    try {

        if(!role.id){
            dispatch({ type: ROLES_SAVE_REQUEST, payload: {role} });
            const { data } = await axios({
                method: 'post',
                data: role,
                url: apiProxy+"/api/Roles",
                headers
            });
            callback(data);
            dispatch({ type: ROLES_SAVE_SUCCESS, payload: data });
        }else{
            dispatch({ type: ROLES_SAVE_REQUEST, payload: {role} });
            const { data } = await axios({
                method: 'patch',
                data: role,
                url: apiProxy+"/api/Roles/" + role.id,
                headers
            });
            callback(data);
            dispatch({ type: ROLES_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: ROLES_SAVE_FAIL, payload: error.message });
    }
    
}

const deleteRole = (role, callback) => async (dispatch) => {
    try {
        dispatch({ type: ROLES_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/roles/" + role.id,
            headers
        });
        callback(data);
        dispatch({ type: ROLES_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ROLES_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedRole = (role) => async (dispatch) => {
    dispatch({ type: SELECTED_ROLE, payload: role });
}

const toggleRolesSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: ROLES_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export {listRoles, saveRole, deleteRole, selectedRole, toggleRolesSidebar}