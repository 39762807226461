import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pathListner } from '../../actions/pathActions';

import { listUsers, selectedUser, toggleUsersSidebar } from '../../actions/usersActions';
import UsersFormSidebar from './UsersFormSidebar';
import UsersFiltre from './UsersFiltre';
import UsersDatatable from './UsersDatatable';
import { listRoles } from '../../actions/rolesActions';
import FileManagerModal from '../FilesManager/FileManagerModal';

export default function Users() {


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(pathListner('/users'));
        dispatch(listRoles());
        dispatch(listUsers());
        
    }, []);

    const addUser = () => {
        dispatch(selectedUser(null));
        dispatch(toggleUsersSidebar(true));
    }
    
    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <FileManagerModal />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                    <div style={{bottom: '54px' , right: '19px'}} className="fixed-action-btn direction-top">
                        <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addUser}>
                            <i className="material-icons">person_add</i>
                        </a>
                    </div>
                    {/* <!-- Add new User popup Ends--> */}

                    <UsersFiltre/>

                    <UsersDatatable />

                    <UsersFormSidebar/>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
        
        </React.Fragment>;
}

